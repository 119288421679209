<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
.header-add-red a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #fe2f2f;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}
.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}
button {
  outline: none !important;
}
.sc-title .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.sc-title h1 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.System_admin_text_division {
  margin-left: -12px;
  font-family: Poppins;
  font-style: normal;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
}

.header-add-division a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

.division_bar {
  padding-top: 55px;
}

@media only screen and (max-width: 768px) {
  .header-add-division {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 599px) {
  .v-application .sc-title {
    margin-top: 20px !important;
  }

  .sc-title h1 {
    font-size: 22px;
  }

  .sc-title h1 {
    font-size: 22px;
  }

  .customer-search-table .v-data-footer {
    margin-top: 0;
  }

  .v-application .customer-search-wrap {
    padding-top: 0 !important;
  }
}
</style>

export const foreman_routes = [
  {
    path: "",
    name: "Foreman",
    component: () => import("./../components/Foreman/ForemanMain"),
  },
  {
    path: "TodayProject",
    name: "TodayProject",
    component: () => import("./../components/Foreman/TodayProject"),
  },
  {
    path: "TodayProjectMain/:id",
    name: "TodayProjectMain",
    component: () => import("./../components/Foreman/TodayProjectMain"),
  },
  {
    path: "TodayBidItems/:id",
    name: "TodayBidItems",
    component: () => import("./../components/Foreman/TodayBidItems"),
  },
  {
    path: "TodayMaterials/:id",
    name: "TodayMaterials",
    component: () => import("./../components/Foreman/TodayMaterials"),
  },
  {
    path: "TodayChecklist/:id",
    name: "TodayChecklist",
    component: () => import("./../components/Foreman/TodayChecklist"),
  },
  {
    path: "TodayFeedback/:id",
    name: "TodayFeedback",
    component: () => import("./../components/Foreman/TodayFeedback"),
  },
  {
    path: "TodayPhotos/:id",
    name: "TodayPhotos",
    component: () => import("./../components/Foreman/TodayPhotos"),
  },
  {
    path: "TodayDocuments/:id",
    name: "TodayDocuments",
    component: () => import("./../components/Foreman/TodayDocuments"),
  },
  {
    path: "TodayCloseOut/:id",
    name: "TodayCloseOut",
    component: () => import("./../components/Foreman/TodayCloseOut"),
  },
  {
    path: "TodayReassign/:id",
    name: "TodayReassign",
    component: () => import("./../components/Foreman/TodayReassign"),
  },
  {
    path: "AllProjects",
    name: "AllProjects",
    component: () => import("./../components/Foreman/AllProjects"),
  },
  {
    path: "TimeClock",
    name: "TimeClock",
    component: () => import("./../components/Foreman/TimeClock"),
  },
  {
    path: "ConfirmClock",
    name: "ConfirmClock",
    component: () => import("./../components/Foreman/ConfirmClock"),
  },
  {
    path: "Prep",
    name: "Prep",
    component: () => import("./../components/Foreman/Prep"),
  },
  {
    path: "PrepBidItems",
    name: "PrepBidItems",
    component: () => import("./../components/Foreman/PrepBidItems"),
  },
  {
    path: "PrepMaterials",
    name: "PrepMaterials",
    component: () => import("./../components/Foreman/PrepMaterials"),
  },
  {
    path: "PrepChecklist",
    name: "PrepChecklist",
    component: () => import("./../components/Foreman/PrepChecklist"),
  },
  {
    path: "Drive2Shop",
    name: "DriveShop",
    component: () => import("./../components/Foreman/Drive2Shop"),
  },
  {
    path: "Drive2Job",
    name: "Drive2Job",
    component: () => import("./../components/Foreman/Drive2Job"),
  },
  {
    path: "ForemanTraining",
    name: "ForemanTraining",
    component: () => import("./../components/Foreman/ForemanTraining"),
  },
  {
    path: "Directory",
    name: "ForemanDirectory",
    component: () => import("./../components/Foreman/Directory"),
  },
  {
    path: "Feedback",
    name: "ForemanFeedback",
    component: () => import("./../components/Foreman/Feedback"),
  },
];

<template>
  <v-footer padless class="main-footer" v-if="!loginPage">
    <v-col class="text-center footer-custome-css" cols="12">
      Copyright. @ 2022 All Right Reserved | Peterson Acquisitions..
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    loginPage: false,
  }),
  watch: {
    $route(to) {
      this.loginPage =
        to.name === "Login" ||
        to.name === "ForgotPassword" ||
        to.name === "BidPages" ||
        to.name === "Bid_page_pdf" ||
        to.name === "Curt_letter" ||
        to.name === "EstimateSummary_pdf" ||
        to.name === "Photos_pdf" ||
        to.name === "RepairPlanDetails_pdf" ||
        to.name === "ProjectDrawing_pdf" ||
        to.name === "Contract_pdf";
    },
  },
};
</script>

<style type="text/css">
footer.v-footer > div {
  font-size: 14px;
  color: #000000;
  padding: 20px 0;
}

.main-footer {
  position: inherit !important;
  margin: 0 auto;
}
</style>

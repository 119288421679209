import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  username: "",
  role: "4",
  canpost: false,
  canput: false,
  candelete: false,
  canviewreports: false,
  date: "",
  time: "",
  address: "",
  city: "",
  phone: "",
  email: "",
  role_desc: "Reader",
  userid: "",
  employeeid: "",
  fullname: "",
  active: 0,
};

const getters = {
  canPost: (state) => state.canpost,
  roleDesc: (state) => state.role_desc,
};

const mutations = {
  user(state, payload) {
    state.username = payload.username;
    state.role = payload.role;
    state.role_desc = payload.role_desc;
    state.userid = payload.userid;
    state.employeeid = payload.employeeid;
    state.fullname = payload.fullname;
    state.active = payload.active;
    state.date = payload.date;
    state.time = payload.time;
    state.address = payload.address;
    state.city = payload.city;
    state.phone = payload.phone;
    state.email = payload.email;
    state.canpost = payload.canpost;
    state.canput = payload.canput;
    state.candelete = payload.candelete;
    state.canviewreports = payload.canviewreports;
  },
};

const actions = {
  updateUser(context, payload) {
    context.commit("customer", payload);
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});

import Vue from "vue";
import { routes } from "./config";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  let isAuthenticated = localStorage.getItem("token");
  if (to.name !== "Login" && to.name !== "CreateAccount" && !isAuthenticated)
    next({ name: "Login" });
  next();
});

export default router;

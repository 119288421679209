import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "token " + token;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export default {
  install: function (vue) {
    vue.prototype.$http = axios;
  },
};

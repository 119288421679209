import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios_config from "./service/axios_config";
// import VueJsonToCsv from 'vue-json-to-csv';
import JsonExcel from "vue-json-excel";
import moment from "vue-moment";
import * as VueGoogleMaps from "vue2-google-maps";
import VueElElements from "vue-el-element";
import store from "./Store/index";
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
import VueGeoloation from "vue-browser-geolocation";
import Vuelidate from "vuelidate";
// import abilityPlugin from './ability-plugin';
//import VueConfirmDialog from 'vue-confirm-dialog'

import Vuex from "vuex";
import "es6-promise/auto";
import jsPDF from "jspdf";
// import number from "@coders-tm/vue-number-format";
import money from "v-money";

// register directive v-money and component <money>
Vue.use(money, { precision: 2 });
// register directive v-number and component <number>
// Vue.use(number, { precision: 2 });

Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAixzxN3TnSuURj12cTclAiuECH37tMXU0",
    libraries: "places", // necessary for places input
  },
});
Vue.use(VueGeoloation);
Vue.use(moment);
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;
Vue.use(axios_config);
Vue.use(VueElElements);
Vue.use(jsPDF);
Vue.use(Vuelidate);
//Vue.use(VueConfirmDialog)
//Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

import "./app.scss";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
// Vue.use(VueJsonToCsv);
// import VueJsonToCsv from 'vue-json-to-csv';

export const EventBus = new Vue();

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
  router,
}).$mount("#app");

<template>
  <v-container class="d-print-none">
    <v-dialog v-model="dialog" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="main-header" v-show="!hideNav">
          <v-container class="pt-0 pb-0">
            <v-layout row>
              <v-col cols="12" class="pt-0 pb-0">
                <div class="header">
                  <div class="header-left">
                    <div class="header-logo">
                      <router-link to="/">
                        <v-img
                          v-if="logged_in"
                          :src="require('../assets/main_logo.png')"
                          contain
                          height="100px"
                          class="img-class"
                        >
                        </v-img>
                      </router-link>
                    </div>

                    <div v-if="logged_in" class="header-options">
                      <ul>
                        <li>
                          <a
                            href="/DealsMain"
                            @click="menu1_active_state = !menu1_active_state"
                            ><img
                              src="../assets/customer-icon.png"
                              alt="" /><span>DEALS</span
                            ><i class="fa fa-caret-down"></i
                          ></a>
                          <ul v-bind:class="{ active: menu1_active_state }">
                            <li v-if="isReporter === 'false'">
                              <a href="/DealsMain"
                                ><img
                                  src="../assets/search-icon.png"
                                  alt="search-icon"
                                /><span>Search</span></a
                              >
                            </li>
                            <li v-if="role < 3">
                              <a
                                href="javascript:void(0)"
                                color="red lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                ><img
                                  src="../assets/plus-icon.png"
                                  alt=""
                                /><span>Add</span></a
                              >
                            </li>
                            <li>
                              <a
                                href="/DealsArchive"
                                color="red lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                ><img
                                  src="../assets/document-archive-icon.png"
                                  alt=""
                                /><span>Archive</span></a
                              >
                            </li>
                          </ul>
                        </li>
                        <!-- <li>
                          <a
                            href="/Schedule"
                            @click="menu4_active_state = !menu4_active_state"
                            ><img src="../assets/book-icon.png" alt="" /><span
                              >TRAINING</span
                            ></a
                          >
                        </li> -->
                        <li>
                          <a
                            href=""
                            @click="menu5_active_state = !menu5_active_state"
                            ><img src="../assets/maint-icon.png" alt="" /><span
                              >REPORTS</span
                            ></a
                          >
                          <ul v-bind:class="{ active: menu1_active_state }">
                            <li v-if="isReporter === 'false'">
                              <a href="/BlindAdSummary"
                                ><img
                                  src="../assets/book-icon.png"
                                  alt="search-icon"
                                /><span>BlindAd Summary</span></a
                              >
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="logged_in" class="header-right">
                    <div class="header-search">
                      <v-text-field
                        append-icon="mdi-magnify"
                        placeholder="Search"
                        @keyup.enter="SearchEntry"
                        v-model="searchCustomer"
                        solo
                      ></v-text-field>
                    </div>
                    <div>
                      <v-btn
                        v-if="role < 3"
                        @click="dialogOpen"
                        class="header-add-location"
                        ><i class="fa fa-plus" aria-hidden="true"></i>
                        ADD
                      </v-btn>
                    </div>

                    <div
                      class="header-user"
                      @mouseover="avtar_active_state = true"
                      @mouseout="avtar_active_state = false"
                    >
                      <div
                        class="user-avtar"
                        @click="avtar_active_state = !avtar_active_state"
                      >
                        <img
                          v-if="!!profileImageUrl"
                          :src="profileImageUrl"
                          :alt="initials"
                          width="50"
                          height="35"
                        />
                        <v-btn small class="mx-1" elevation="1" v-else fab>{{
                          initials
                        }}</v-btn>
                        <i class="fa fa-chevron-down"></i>
                      </div>
                      <div
                        class="user-option"
                        :class="{ active: avtar_active_state }"
                      >
                        <ul>
                          <li>
                            <a href="/MyProfile"
                              ><img
                                src="../assets/profile-icon.png"
                                alt="search-icon"
                              /><span>My Profile</span></a
                            >
                          </li>
                          <li v-if="isAdmin === 'true'">
                            <a href="/SystemAdmin"
                              ><img
                                src="../assets/system-admin-icon.png"
                                alt=""
                              /><span>System Admin</span></a
                            >
                          </li>

                          <li>
                            <a href="/Help"
                              ><img
                                src="../assets/help-icon.png"
                                alt="reports-icon"
                              /><span>Help</span></a
                            >
                          </li>
                          <li>
                            <a @click="logOut"
                              ><img
                                src="../assets/logout-icon.png"
                                alt="reports-icon"
                              /><span>Log Out</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!logged_in && loginPage">
                    <div class="header-add mb-5 mt-5"></div>
                  </div>
                  <div v-else>
                    <div class="header-add mb-5 mt-5">
                      <a href="/Login"> Login </a>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-layout>
          </v-container>
        </div>
      </template>
      <v-card>
        <v-dialog v-model="dialog" max-width="1200" persistent>
          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="close">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{ editedItem.id ? "Update Deal Record" : "Add Deal" }}
                    </h6>
                    <!-- <b-button @click="sendAdminEmail">Test Admin Email</b-button> -->
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-col cols="12" sm="12">
                            <div id="bsText">
                              <b-input-group size="sm">
                                <template #prepend size="sm">
                                  <b-input-group-text size="sm"
                                    >Deal Name</b-input-group-text
                                  >
                                </template>
                                <b-form-input
                                  size="sm"
                                  v-model="editedItem.deal_name"
                                  @change="getDup"
                                  persistent-hint
                                  :rules="[
                                    (v) => !!v || 'This field is required',
                                  ]"
                                ></b-form-input>
                              </b-input-group>
                              <p></p>
                              <p v-if="!dealnameIsValid" class="err-message">
                                Please enter the Deal Name
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <template id="AdminUser">
                              <div>
                                <label><b>Admin User</b></label>
                                <b-form-select
                                  v-model="editedItem.admin_user_id"
                                  :options="list_admin_user"
                                  class="mb-3"
                                  value-field="id"
                                  text-field="text"
                                  disabled-field="notEnabled"
                                  :rules="[
                                    (v) => !!v || 'This field is required',
                                  ]"
                                ></b-form-select>
                              </div>
                            </template>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <template id="City">
                              <div>
                                <label><b>City</b></label>
                                <b-form-input
                                  v-model="editedItem.city"
                                ></b-form-input>
                              </div>
                            </template>
                          </v-col>
                          <v-col cols="12" sm="3" class="State">
                            <template id="State">
                              <div>
                                <label><b>State</b></label>
                                <b-form-select
                                  v-model="editedItem.state_id"
                                  :options="list_states"
                                  class="mb-3"
                                  value-field="id"
                                  text-field="text"
                                  disabled-field="notEnabled"
                                ></b-form-select>
                              </div>
                            </template>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <template id="Zip">
                              <div>
                                <label><b>Zip</b></label>
                                <b-form-input
                                  v-model="editedItem.zip"
                                  v-mask="mask.zipCode"
                                  class="mb-3"
                                ></b-form-input>
                              </div>
                            </template>
                          </v-col>
                          <label class="div-header down-15"
                            ><b>Select Brokers</b></label
                          >
                          <v-col cols="10"></v-col>
                          <b-button class="datatable-button" @click="addBroker"
                            >Add Broker</b-button
                          >
                          <v-row>
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-text-field
                                label="Search"
                                v-model="searchText"
                                clearable
                                @click:clear="clearSearch"
                                append-icon="mdi-magnify"
                                @keyup.enter="getBrokerData"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                              <v-btn outlined rounded @click="getBrokerData"
                                >Search</v-btn
                              >
                            </v-col>
                            <v-col cols="12" md="4" class="">
                              <label style="top-margin: 15px"
                                ><b>Selected Brokers</b>
                              </label>
                            </v-col>
                          </v-row>
                          <v-col cols="6">
                            <div class="down-5">
                              <v-data-table
                                dense
                                class="customer-search-table cursor-pointer"
                                :headers="brokerHeaders"
                                :items="brokerItems"
                                :loading="loading"
                                sort-by="id"
                                sort-desc
                                @click:row="selectBroker"
                                :footer-props="{
                                  'items-per-page-options': [3, 5, 7, 10, -1],
                                }"
                                :items-per-page="5"
                              >
                              </v-data-table>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="down-5">
                              <v-data-table
                                dense
                                class="customer-search-table cursor-pointer"
                                :headers="selectedBrokerHeaders"
                                :items="selectedBrokerItems"
                                :loading="loading"
                                sort-by="id"
                                sort-desc
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon
                                    class="action-icon"
                                    v-if="canput === 'true'"
                                    color="warning"
                                    small
                                    @click="editedItems(item,'broker')"
                                    >fas fa-edit</v-icon
                                  >
                                  <v-icon
                                    class="action-icon"
                                    v-if="candelete === 'true'"
                                    color="error"
                                    small
                                    @click="deleteBrokerItem(item.id)"
                                    >fas fa-trash-alt</v-icon
                                  >
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>

                          <v-col cols="10"></v-col>
                      <v-col cols="2">
                        <b-button class="save-button" @click="saveFormData"
                          >Save</b-button
                        >
                        <b-button class="uploads-button" @click="showDialog2"
                          >Next</b-button
                        >                        
                      </v-col>

                          <v-col cols="12">
                            <div class="text-right"></div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-if="dialog2" v-model="dialog2" max-width="1200" persistent>
          <v-card style="background-color: aquamarine">
            <div>
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="close">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{
                        editedItem.id
                          ? "Update Seller Record"
                          : "Add Deal Seller"
                      }}
                    </h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-col cols="12" sm="12">
                            <div id="bsText">
                              <b-input-group size="sm">
                                <template #prepend size="sm">
                                  <b-input-group-text size="sm"
                                    >Deal Name</b-input-group-text
                                  >
                                </template>
                                <b-form-input
                                  size="sm"
                                  v-model="editedItem.deal_name"
                                  @change="getDup"
                                  persistent-hint
                                  :rules="[
                                    (v) => !!v || 'This field is required',
                                  ]"
                                ></b-form-input>
                              </b-input-group>
                              <p></p>
                              <p v-if="!dealnameIsValid" class="err-message">
                                Please enter the Deal Name
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <template id="AdminUser">
                              <div>
                                <label><b>Admin User</b></label>
                                <b-form-select
                                  v-model="editedItem.admin_user_id"
                                  :options="list_admin_user"
                                  class="mb-3"
                                  value-field="id"
                                  text-field="text"
                                  disabled-field="notEnabled"
                                  :rules="[
                                    (v) => !!v || 'This field is required',
                                  ]"
                                ></b-form-select>
                              </div>
                            </template>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <template id="City">
                              <div>
                                <label><b>City</b></label>
                                <b-form-input
                                  v-model="editedItem.city"
                                  class="mb-3"
                                ></b-form-input>
                              </div>
                            </template>
                          </v-col>

                          <v-col cols="12" sm="3" class="State">
                            <template id="State">
                              <div>
                                <label><b>State</b></label>
                                <b-form-select
                                  v-model="editedItem.state_id"
                                  :options="list_states"
                                  class="mb-3"
                                  value-field="id"
                                  text-field="text"
                                  disabled-field="notEnabled"
                                ></b-form-select>
                              </div>
                            </template>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <template id="Zip">
                              <div>
                                <label><b>Zip</b></label>
                                <b-form-input
                                  v-model="editedItem.zip"
                                  v-mask="mask.zipCode"
                                  class="mb-3"
                                ></b-form-input>
                              </div>
                            </template>
                          </v-col>
                          <label class="div-header down-15"
                            ><b>Select Sellers</b></label
                          >
                          <v-col cols="8"></v-col>
                          <b-button
                            style="text-align: left"
                            class="datatable-button"
                            @click="ghlSeller"
                            >Engage GHL Seller</b-button
                          >
                          <b-button
                            style="text-align: right"
                            class="datatable-button"
                            @click="addSeller"
                            >Add Seller</b-button
                          >                         
                          <v-row style="margin-top: 15px;">
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-text-field
                                label="Search"
                                v-model="searchText"
                                clearable
                                @click:clear="clearSearch"
                                append-icon="mdi-magnify"
                                @keyup.enter="getSellerData"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="down-15">
                              <v-btn outlined rounded @click="getSellerData"
                                >Search</v-btn
                              >
                            </v-col>
                            <v-col cols="12" md="4" class="">
                              <label style="top-margin: 15px"
                                ><b>Selected Sellers</b>
                              </label>
                            </v-col>
                          </v-row>
                          <v-col cols="6">
                            <div class="down-5">
                              <v-data-table
                                dense
                                class="customer-search-table cursor-pointer"
                                :headers="sellerHeaders"
                                :items="sellerItems"
                                :loading="loading"
                                sort-by="id"
                                sort-desc
                                @click:row="selectSeller"
                                :footer-props="{
                                  'items-per-page-options': [3, 5, 7, 10, -1],
                                }"
                                :items-per-page="5"
                              >
                              </v-data-table>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div class="down-5">
                              <v-data-table
                                dense
                                class="customer-search-table cursor-pointer"
                                :headers="selectedSellerHeaders"
                                :items="selectedSellerItems"
                                :loading="loading"
                                sort-by="id"
                                sort-desc
                              >
                                <template v-slot:item.actions="{ item }">
                                  <v-icon
                                    class="action-icon"
                                    v-if="canput === 'true'"
                                    color="warning"
                                    small
                                    @click="editedItems(item,'seller')"
                                    >fas fa-edit</v-icon
                                  >
                                  <v-icon
                                    class="action-icon"
                                    v-if="candelete === 'true'"
                                    color="error"
                                    small
                                    @click="deleteSellerItem(item.id)"
                                    >fas fa-trash-alt</v-icon
                                  >
                                </template>
                              </v-data-table>
                            </div>
                          </v-col>
                          <v-col cols="9"></v-col>
                      <v-col cols="2">
                        <b-button-group class="mt-5 ml-2">
                          <b-button @click="showDialog1" class="worksheets-button"
                            >Previous</b-button
                          >
                          <b-button @click="saveFormData('seller')" class="save-button"
                            >Save</b-button
                          >                         
                          <b-button @click="close" class="next-button"
                            >Close</b-button
                          >
                        </b-button-group>
                      </v-col>

                          <v-col cols="12">
                            <div class="text-right">
                              <!-- <v-btn color="primary" dark @click="saveFormData">
                                Save
                              </v-btn> -->
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBroker" max-width="600" persistent>
          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="closeBroker">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{
                        brokerItem.id
                          ? "Update Broker Record"
                          : "Add Broker Record"
                      }}
                    </h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-row class="pr-10 pl-10">
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="brokerItem.uid"
                                    @change="getDupUID(brokerItem .uid)"
                                    label="UID"
                                    :rules="[
                                    (v) => v.trim() !== '' || 'This field is required',
                                    ]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="brokerItem.first_name"
                                    label="First Name"
                                    :rules="[
                                      (v) => v.trim() !== '' || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="brokerItem.last_name"
                                    label="Last Name"
                                    :rules="[
                                      (v) => v.trim() !== '' || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col id="zip" cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Zip"
                                    v-model="brokerItem.zip"
                                    v-mask="mask.zipCode"
                                    @change="onZipChange('broker')"
                                  ></v-text-field>
                                </v-col>                                
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="City"
                                    v-model="brokerItem.city_id"
                                    :items="list_city"
                                    item-value="id"
                                    item-text="text"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="State"
                                    v-model="brokerItem.state_id"
                                    :items="list_states"
                                    item-value="id"
                                    item-text="text"
                                    @change="onStateChange('broker')"
                                     
                                  ></v-autocomplete>
                                </v-col>

                              </v-row>

                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Company"
                                    v-model="brokerItem.company"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Phone"
                                    v-model="brokerItem.phone"
                                    v-mask="mask.phone"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Text"
                                    v-model="brokerItem.text_no"
                                    v-mask="mask.phone"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Password"
                                    v-model="brokerItem.django_pwd"
                                    :rules="[
                                      (v) => v.trim() !== '' || 'This field is required',
                                    ]"

                                  ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-checkbox
                                    label="Active"
                                    v-model="brokerItem.active"
                                    :true-value="true"
                                    :false-value="false"
                                  ></v-checkbox>
                                </v-col> -->
                              </v-row>
                              <v-row> </v-row>

                              <v-row> </v-row>
                            </v-col>
                          </v-row>

                          <v-col cols="12">
                            <div class="text-right">
                              <v-btn
                                color="primary"
                                dark
                                @click="saveBrokerFormData"
                              >
                                Save
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogSeller" max-width="600" persistent>
          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="closeSeller">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{
                        editedSellerItem.id
                          ? "Update Seller Record"
                          : "Add Seller Record"
                      }}
                    </h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-row class="pr-10 pl-10">
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedSellerItem.uid"
                                    @change="getDupUID(editedSellerItem.uid)"
                                    label="UID"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedSellerItem.first_name"
                                    label="First Name"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedSellerItem.last_name"
                                    label="Last Name"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Password"
                                    v-model="editedSellerItem.django_pwd"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Email"
                                    v-model="editedSellerItem.email"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col id="zipSeller" cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Zip"
                                    v-model="editedSellerItem.zip"
                                    v-mask="mask.zipCode"
                                    @change="onZipChange()"
                                  ></v-text-field>
                                </v-col>                                
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="City"
                                    v-model="editedSellerItem.city_id"
                                    :items="list_city"
                                    item-value="id"
                                    item-text="text"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="State"
                                    v-model="editedSellerItem.state_id"
                                    :items="list_states"
                                    item-value="id"
                                    item-text="text"
                                    @change="onStateChange()"
                                  ></v-autocomplete>
                                </v-col>

                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Company"
                                    v-model="editedSellerItem.company"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Phone"
                                    v-model="editedSellerItem.phone"
                                    v-mask="mask.phone"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Text"
                                    v-model="editedSellerItem.text_no"
                                    v-mask="mask.phone"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row> </v-row>

                              <v-row> </v-row>
                            </v-col>
                          </v-row>

                          <v-col cols="12">
                            <div class="text-right">
                              <v-btn
                                color="primary"
                                dark
                                @click="saveSellerFormData"
                              >
                                Save
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dlgGHL" max-width="1000" persistent>
          <v-card>
            <div >
                <div class="popup-wrap">
                  <div class="pop-up-header">Engage GHL Seller Record</div>

                  <v-btn class="close-popup" text @click="closeGHL">
                    <v-img
                      src="../assets/close-icon.png"
                      alt="close-icon"
                    ></v-img>
                  </v-btn>

                  <v-row id="GHL Email" style="margin-top: 1px;">
                    <v-col cols="10">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text style="font-family: Lato, sans-serif;
                         ">Seller Email:</b-input-group-text>
                        </template>
                        <b-form-input
                          v-model="ghlSellerItem.email"
                          style="background-color: lightgoldenrodyellow; 
                          margin-right: 1px;
                          padding-right: 0px; 
                          text-align: center;"
                          
                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>
                    <v-col cols="2">
                      <b-button  class="engage-button" 
                        @click="getGHLUser"
                      >
                        Get Contact
                      </b-button>
                    </v-col>
                  </v-row> 
                  
                  <v-row id="Company">  
                    <v-col cols="12 ">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Company:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.company"
                          style="background-color: white; text-align: center;"
                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>


                  </v-row>                 
                  <v-row id="First - Last Name/Telephone">
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">First Name:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.first_name"
                          style="background-color: white"

                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Last Name:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.last_name"
                          style="background-color: white"

                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Tel:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.phone"
                          style="background-color: white"

                        >city_id
                        </b-form-input>
                      </b-input-group>
                    </v-col>                  
                  </v-row>  
                  
                  <v-row id="City/State/Zip">
                    <v-col cols="5">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">City:</b-input-group-text>
                        </template>
                        <b-form-select class="input-standard"
                          v-model="ghlSellerItem.city_id"
                          style="background-color: white"
                          :options="list_city"
                          value-field="id"
                          text-field="text"                       
                        >
                        </b-form-select>
                      </b-input-group>
                    </v-col>
                    <v-col cols="3">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">State:</b-input-group-text>
                        </template>
                        <b-form-select class="input-standard"
                          v-model="ghlSellerItem.state_id"
                          style="background-color: white"
                          :options="list_states"
                          value-field="id"
                          text-field="text"                       
                        >
                        </b-form-select>
                      </b-input-group>
                    </v-col>
                    <v-col cols="4">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text 
                          class="input-standard">Zip:</b-input-group-text>
                        </template>
                        <b-form-input class="input-standard"
                          v-model="ghlSellerItem.zip"
                          style="background-color: white"
                        >
                        </b-form-input>
                      </b-input-group>
                    </v-col>

                  
                    <v-row id="UID/Pwd/Tags">
                      <v-col cols="2">
                        <b-input-group>

                          <b-input-group>
                            <template #prepend>
                              <b-input-group-text 
                                class="input-standard">UID:</b-input-group-text>
                            </template>
                            <b-form-input class="input-standard"
                              v-model="ghlSellerItem.uid"
                              @blur="getDupUID(ghlSellerItem.uid)"
                              style="background-color: white; border-color: red; text-align: center;"
                              :rules="[
                                        (v) => !!v || 'This field is required',
                                      ]"                          
                            >
                            </b-form-input>
                          </b-input-group>

                          
                        </b-input-group>
                        <p v-if="!ghlSellerItem.uid" class="error"
                        style="margin-left: 37px; 
                          text-align:center; 
                          font-family: 'Lato', sans-serif; 
                          font-size: 11px;
                          font-weight: 600;"
                        >You must enter a UID for this Seller</p>
                      </v-col> 
                      <v-col cols="4">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text 
                            class="input-standard">Password:</b-input-group-text>
                          </template>
                          <b-form-input class="input-standard"
                            v-model="ghlSellerItem.django_pwd"
                            style="background-color: white; border-color: red; text-align: center;"
                            :rules="[
                                        (v) => !!v || 'This field is required',
                                      ]"                          
                          >
                          </b-form-input>
                          
                        </b-input-group>
                        <p v-if="!ghlSellerItem.django_pwd" class="error"
                          style="margin-left: 67px; 
                          text-align:center; 
                          font-family: 'Lato', sans-serif; 
                          font-size: 11px;
                          font-weight: 600;"
                        >You must assign a password to this Seller</p>
                      </v-col>                      

                      <v-col cols="6">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text 
                            class="input-standard">Tags:</b-input-group-text>
                          </template>
                          <b-form-input class="input-standard"
                            v-model="tags"
                            style="background-color:azure; border-color: red; text-align: center;"
                          
                          >
                          </b-form-input>
                        </b-input-group>
                      </v-col>                                     
                  </v-row>                 

                  </v-row> 
                  <p style="margin-top: 50px"></p>
                  <button class="save-button-ghl" @click="saveGHLFormData">Save Seller</button>                  
                  <button class="engage-button-ghl" @click="engageGHLSeller">Save and Engage GHL Seller</button>        

                </div>

        </div> 
        
      </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBuyer" max-width="600" persistent>
          <v-card>
            <div class="">
              <div class="popup-wrap">
                <v-btn class="close-popup" text @click="closeBuyer">
                  <v-img
                    src="../assets/close-icon.png"
                    alt="close-icon"
                  ></v-img>
                </v-btn>

                <div class="popup-wrap-inner step-one">
                  <div class="popup-title">
                    <h6>
                      {{
                        editedItem.id
                          ? "Update Buyer Record"
                          : "Add Buyer Record"
                      }}
                    </h6>
                  </div>
                  <div class="customer-form">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-container>
                        <v-row class="px-5">
                          <v-row class="pr-10 pl-10">
                            <v-col cols="12" sm="12">
                              <v-row>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedItem.uid"
                                    @change="getDupUID(editedItem.uid)"
                                    label="UID"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedItem.first_name"
                                    label="First Name"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" class="pt-0 pb-0">
                                  <v-text-field
                                    v-model="editedItem.last_name"
                                    label="Last Name"
                                    :rules="[
                                      (v) => !!v || 'This field is required',
                                    ]"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Password"
                                    v-model="editedItem.django_pwd"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-checkbox
                                    label="Active"
                                    v-model="editedItem.active"
                                    :true-value="true"
                                    :false-value="false"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="City"
                                    v-model="editedItem.city"
                                    :items="list_city"
                                    item-value="id"
                                    item-text="text"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-0 pb-0">
                                  <v-autocomplete
                                    label="State"
                                    v-model="editedItem.state_id"
                                    :items="list_states"
                                    item-value="id"
                                    item-text="text"
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="3" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Zip"
                                    v-model="editedItem.zip"
                                    v-mask="mask.zipCode"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Company"
                                    v-model="editedItem.company"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Phone"
                                    v-model="editedItem.phone"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6" class="pt-0 pb-0">
                                  <v-text-field
                                    label="Text"
                                    v-model="editedItem.text_no"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row> </v-row>

                              <v-row> </v-row>
                            </v-col>
                          </v-row>

                          <v-col cols="12">
                            <div class="text-right">
                              <v-btn
                                color="primary"
                                dark
                                @click="saveBuyerFormData"
                              >
                                Save
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </v-card>

      <template>
        <div>
          <b-modal
            id="modal-center"
            v-model="dialogAddOK"
            centered
            title="Addition Successful!"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            :body-bg-variant="bodyBgVariant"
            :body-text-variant="bodyTextVariant"
            :footer-bg-variant="footerBgVariant"
            :footer-text-variant="footerTextVariant"
          >
            <b-row class="mb-1 text-center">
              <b-col cols="12"></b-col>
              <b-col>New record successfully added to database!</b-col>
            </b-row>

            <template #modal-footer>
              <div class="w-100">
                <p class="float-left"></p>
                <b-button
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click="dialogAddOK = false"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
        </div>

        <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>
      </template>

      <v-dialog v-model="dialogNoPermission" persistent max-width="500px">
        <v-card>
          <v-card-title class="headline">
            <span
              >Sorry.. You do not have the proper permissions for this
              operation</span
            >
          </v-card-title>
          <v-card-text class="subtitle-1 pa-5">
            <span>Insufficient access level...</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="dialogNoPermission = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div>
        <b-modal
          id="modal-center"
          v-model="dlgError"
          centered
          :title="errorTitle"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          :body-text-variant="bodyTextVariant"
          :footer-bg-variant="footerBgVariant"
          :footer-text-variant="footerTextVariant"
        >
          <b-row class="mb-1 text-center">
            <b-col cols="12"></b-col>
            <b-col>{{ errorText }}</b-col>
          </b-row>

          <template #modal-footer>
            <div class="w-100">
              <p class="float-left"></p>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="dlgError = false"
              >
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>

      <div>
        <b-modal id="modal-uid"
          v-model="dlgUID"
          centered
          :title="errorTitle"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          :body-bg-variant="bodyBgVariant"
          :body-text-variant="bodyTextVariant"
          :footer-bg-variant="footerBgVariant"
          :footer-text-variant="footerTextVariant"
        >
          <b-row class="mb-1 text-center">
            <b-col cols="12"></b-col>
            <b-col>{{ errorText }}</b-col>
          </b-row>

          <template #modal-footer>
            <div class="w-100">
              <p class="float-left"></p>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="dlgUID = false"
              >
                Close
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>      
    </v-dialog>

    <v-dialog v-model="dialogDeleteBroker" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteBrokerItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteSeller" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteSellerItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteBuyer" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteBuyerItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <div id="modal-yes-no">
      <b-modal
        id="modal-center"
        v-model="dlgModal"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="sendBrokerEmail()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModal = false" class="back-button"
                >No</b-button
              >
            </b-button-group>

            <!-- <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="sendBrokerEmail"
              >
                Yes
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="float-right"
                @click="dlgError = false"
              >
                No
              </b-button>               -->
          </div>
        </template>
      </b-modal>
    </div>

    <div id="AdminModal">
      <b-modal
        id="admin-modal"
        v-model="dlgModalAdmin"
        centered
        :title="errorTitle"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
      >
        <b-row class="mb-1 text-center">
          <b-col cols="12"></b-col>
          <b-col>{{ errorText }}</b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <p class="float-right"></p>
            <b-button-group class="float-right">
              <b-button @click="sendAdminEmail()" class="offers-button"
                >Yes</b-button
              >
              <b-button @click="dlgModalAdmin = false" class="back-button"
                >No</b-button
              >
            </b-button-group>
          </div>
        </template>
      </b-modal>
    </div>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { EventBus } from "../main";
import HeaderToggler from "../components/mixins/HeaderToggler";
import { mask } from "vue-the-mask";
import CommonServices from "../service/CommonServices";
import store from "../Store/index";
import XLSX from "xlsx";
import axios from "axios";

export default {
  mixins: [HeaderToggler],
  directives: { mask },

  data() {
    return {
      componentURL: "Deals",
      componentURL_admin: `Users/?user_type_id=1`,
      componentURL_broker: `Users/?user_type_id=2`,
      componentURL_seller: `Users/?user_type_id=3`,
      componentURL_buyer: `Users/?user_type_id=4`,
      store: store,
      role_desc: localStorage.getItem("role_desc"),
      role: parseInt(localStorage.getItem("role")),
      canpost: localStorage.getItem("canpost"),
      canput: localStorage.getItem("canput"),
      candelete: localStorage.getItem("candelete"),
      canviewreports: localStorage.getItem("canviewreports"),
      isAdmin: localStorage.getItem("Admin"),
      isReporter: localStorage.getItem("Reporter"),
      teamAccess: localStorage.getItem("teamAccess"),
      fieldAccess: localStorage.getItem("fieldAccess"),
      DIY: localStorage.getItem("DIY"),
      currentUser: localStorage.getItem("UID"),
      currentUserID: localStorage.getItem("userid"),
      currentEmail: localStorage.getItem("email"),
      dupItemID: localStorage.getItem("dupItemID"),
      show: false,
      searchText: "",
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      dlgModal: false,
      dlgModalAdmin: false,
      dlgGHL: false,
      list_admin_user: [],
      list_broker: [],
      list_seller: [],
      list_buyer: [],
      list_states: [],
      list_city: [],
      snackbar: false,
      disableAddCustomer: false,
      enableAddCustomer: false,
      dialogNoPermission: false,
      dialogDeleteBroker: false,
      dialogDeleteSeller: false,
      dialogDeleteBuyer: false,
      dlgUID: false,
      snackColor: "",
      snackText: "",
      valid: false,
      dialogAddOK: false,
      searchCustomer: "",
      mylat: "",
      mylng: "",
      brokerFname: "",
      brokerLname: "",
      brokerEmail: "",
      adminFname: "",
      adminLname: "",
      adminEmail: "",
      bAdmin: false,
      ghlEmail: "",
      contact: "",
      tags: "",
      zipCodesList: [],

      Buyer: {
        deal_id: "",
        buyer_id: "",
      },

      editedItem: {
        id: "",
        deal_name: "",
        admin_user_id: 43,
        buyer_id: "",
        city: "",
        state_id: "",
      },

      editedSellerItem: {},

      ghlSellerItem: {
        id: "",
        company: "",
        first_name: "",
        last_name: "",
        city_id: "",
        state_id: "",
        zip: "",  
        phone: "",
        email: "",
        user_type_id: 3,
      },

      engagementItem: {
        id: "",
        deal_id: this.currentDealID,
        company_name: "",
        company_dba_name: "",
        primary_seller_first_name: "",
        primary_seller_last_name: "",
        other_sellers_names: "",  
        engagement_start_date: this.$moment(String(Date())).format('MM/DD/YYYY'),
        commission_rate: 0,
        annual_receipts: 0,
        annual_cashflow: 0,
        list_price: 0,
        minimum_acceptable_sales_price: 0,        
      },

      brokerItem: {
        id: "",
        user_type_id: 2,
        first_name: "",
        last_name: "",
        city: "",
        state_id: "",
        zip: "",
      },

      sellerItem: {
        id: "",
        first_name: "",
        last_name: "",
        city: "",
        state_id: "",
        zip: "",
      },

      dupItem: {
        id: "",
        deal_name: "",
        admin_user_id: "",
        broker_id: "",
        seller_id: "",
        buyer_id: "",
        city: "",
        state_id: "",
      },

      adminItem: {
        id: "",
        user_type_id: "",
        first_name: "",
        last_name: "",
        email: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
      required: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      zipRules: [
        (v) => !!v || "This field is required",
        (v) => /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || "Please enter valid zip",
      ],
      logged_in:
        localStorage.getItem("token") !== null &&
        localStorage.getItem("DIY") == "false",
      dialog: false,
      dialogBroker: false,
      dialogSeller: false,
      dialogBuyer: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      hideNav: false,
      loginPage: false,
      loading: false,
      current_step: "1",
      button_active_state: false,
      avtar_active_state: false,
      menu1_active_state: false,
      menu2_active_state: false,
      menu3_active_state: false,
      menu4_active_state: false,
      profileImageUrl: localStorage.getItem("user_photo_file"),
      initials: this.setInitials(),
      menu5_active_state: false,
      currentDealID: 0,
      adminUserID: 0,

      brokerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
      ],

      selectedBrokerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      sellerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
      ],

      selectedSellerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      buyerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
      ],

      selectedBuyerHeaders: [
        {
          text: "First Name",
          class: "small-tableheader",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Last Name",
          class: "small-tableheader",
          value: "last_name",
          sortable: true,
        },
        {
          text: "City",
          class: "small-tableheader",
          value: "city",
          sortable: true,
        },
        {
          text: "State",
          class: "small-tableheader",
          value: "state",
          sortable: true,
        },
        {
          text: "Zip",
          class: "small-tableheader",
          value: "zip",
          sortable: false,
        },
        { text: "Actions", value: "actions", sortable: false },
      ],

      buyerItems: [],
      sellerItems: [],
      brokerItems: [],
      curbrokerItem: [],
      selectedBrokerItems: [],
      selectedSellerItems: [],
      selectedBuyerItems: [],
      list_state: [],
      customers: [],
    };
  },

  computed: {
    dealnameIsValid() {
      return !!this.editedItem.deal_name;
    },

    dealnameIsUnique() {
      return this.dupItemID == "0";
    },

    formIsValid() {
      return this.dealnameIsValid;
    },
  },

  created() {
    this.headerToggler();
    EventBus.$on("toggle-nav", (value) => {
      this.logged_in = value;
    });

    console.log("DIY: ", this.DIY);

    CommonServices.getList("vwAdminUserList")
      .then((res) => {
        console.log("AdminUsers", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_admin_user.push({
            id: code.id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(this.componentURL_broker)
      .then((res) => {
        console.log("BrokerUsers", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_broker.push({
            id: code.user_id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(this.componentURL_seller)
      .then((res) => {
        console.log("SellerUsers", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_seller.push({
            id: code.id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList(this.componentURL_buyer)
      .then((res) => {
        console.log("buyerUsers", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_buyer.push({
            id: code.id,
            text: code.first_name + " " + code.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Cities")
      .then((res) => {
        console.log("Cities", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_city.push({ id: code.id, text: code.city })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("States")
      .then((res) => {
        console.log("States", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_states.push({ id: code.id, text: code.state })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  updated() {
    localStorage.setItem("DIY", false);
    this.logged_in =
      localStorage.getItem("token") !== null &&
      localStorage.getItem("DIY") == "false";
    console.log("Logged in:", this.logged_id);
  },

  mounted() {
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },

  methods: {
    async onStateChange(type) {
    if (type=='broker'){
      const { brokerItem } = this;
      await CommonServices.getZipCode(brokerItem.city_id, brokerItem.state_id, brokerItem, 'zip');      
    }else{
      const { editedSellerItem } = this;
      await CommonServices.getZipCode(editedSellerItem.city_id, editedSellerItem.state_id, editedSellerItem, 'zip');      
     
    }
    this.$forceUpdate();
     },

     async onZipChange(type) {
    if (type=='broker'){
      const { brokerItem } = this;
      await CommonServices.getCityState(brokerItem.zip,brokerItem,'city_id','state_id');      
    }else{
      const { editedSellerItem } = this;
      await CommonServices.getCityState(editedSellerItem.zip,editedSellerItem, 'city_id','state_id');      
     
    }
    this.$forceUpdate();
     },

    ghlSeller() {
      this.dlgGHL=true;
      CommonServices.getList('ZipCodes').then((res) => {
            this.zipCodesList = res.results;
            console.log('ZipCodes List:',this.zipCodesList)
        }).catch((err) => {
            console.log(err);
        });     
    },

    async getGHLUser(){
      console.log('Pulling GHL user info for',this.ghlSellerItem.email)
      try {
        const response = await axios.get(`/db/contact?email=${this.ghlSellerItem.email}`);
        if(Array.isArray(response.data)){
          console.log('Is Array')
          const [contact] = response.data;
          this.contact = contact;
          console.log('Contact:', this.contact.contacts)
          console.log ('Email',this.contact.contacts.email)
        } else {
          console.log('Is NOT Array')
          this.contact = response.data;
          console.log('Contact:', this.contact.contacts);
          console.log('First Name:', this.contact.contacts[0 ].firstName);
          this.ghlSellerItem.company=this.contact.contacts[0].company_name;
          this.ghlSellerItem.first_name=this.contact.contacts[0].firstName;
          this.ghlSellerItem.last_name=this.contact.contacts[0].lastName;
          this.ghlSellerItem.phone=this.contact.contacts[0].phone;
          this.ghlSellerItem.zip=this.contact.contacts[0].postalCode; 
          this.getCityState(this.ghlSellerItem.zip)
          this.tags=this.contact.contacts[0].tags;
          this.ghlSellerItem.uid= await this.getUID(this.contact.contacts[0].firstName) ; 
          this.ghlSellerItem.django_pwd=this.generatePassword(8);    
          this.$forceUpdate(); 
        }
      } catch (error) {
        console.error(error);
      }      
    },

    async getUID(baseName) {
      try {
        let uid = baseName;
        let hasDuplicates = true;
        while (hasDuplicates) {
          const res = await CommonServices.getList(`Users/?uid=${uid}`);
          console.log("Check UID Response: ", res);
          if (res.count === 0) {
            hasDuplicates = false;
          } else {
            uid = `${baseName}${res.count + 1}`;
          }
        }
        return uid;
      } catch (err) {
        console.log(err);
        return false;
      }
    },



     generatePassword(length) {
      var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    getCityState(zip) {
      const filterZipData = this.zipCodesList.find(item => item.zipCode == zip);
      if(!filterZipData){
          console.log('No ZipCode found for', zip)
          return false
      } else{
        console.log('Found CityID:',filterZipData.city_id)
        this.ghlSellerItem.city_id = filterZipData.city_id;
        this.ghlSellerItem.state_id = filterZipData.state_id;
      }               

  },
  
    engageGHLSeller() {
      //first save the GHL Seller info to the Users table
      var validated
      validated=this.saveGHLFormData();
      if (validated==false){return}
      this.close();

      
      
        
    },

    async saveEngagementItem(){
      console.log('Saving Engagement Item...')
      await CommonServices.addRecord("EngagementAgreement", this.engagementItem)
            .then((response) => {
              console.log("Added engagementItem item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Error adding engagementItem record";
              }
            }).finally
            this.$router.push(`/EngagementAgreement/${this.currentDealID}`);

            
    },

 
    sendSellerEmails() {
      this.sellerItems.forEach((item) => this.sendSellerEmail(item));
      this.dlgModal = false;
    },

    async sendSellerEmail(item) {
      console.log(
        "Sending seller email...",
        item.first_name,
        item.last_name,
        item.email
      );
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${item.first_name || "New"} ${item.last_name || "User"}`,
        to_email: item.email,
        subject: "PETERSON CLIENT PENDING UPLOADS",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          item.first_name +
          " " +
          item.last_name +
          ",</p>\
          <p>Please find attached the listing of uploads pending on your account.</p>\
          <p><strong>Please login to https://petersonclient.com/ to address these uploads.</strong> </p>",
      });
    },

    getPendingUploads(sellerid) {
      CommonServices.listWithParams(
        `vwSellerUploadsNeeded/?seller_id=${sellerid}`
      )
        .then((response) => {
          this.pendingUploads = response.results;
          console.log("pendingUploads:", this.pendingUploads);
          this.exportSellerUploadsToExcel();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error retrieving pendingUploads list... ";
          console.log(error);
        });
    },

    exportSellerUploadsToExcel() {
      var reportWS = XLSX.utils.json_to_sheet(this.pendingUploads);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, reportWS, "Pending Uploads"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Pending Uploads.xlsx");
    },

    async sendAdminEmail() {
      console.log(
        "Sending Admin email to:",
        this.adminFname + " " + this.adminLname
      );
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${this.adminFname || "New"} ${this.adminLname || "User"}`,
        to_email: this.adminEmail,
        subject: "NEW DEAL ASSIGNMENT",
        text_content:
          "Thanks for your recent signup on http://petersonclient.com/",
        html_content:
          "<p>Dear " +
          this.adminFname +
          " " +
          this.adminLname +
          ",</p>\
            <p>The following Deal Assignment has just been initiated:</p>\
            <p> &nbsp; &nbsp; Deal Name: " +
          this.editedItem.deal_name +
          "</p>\
            <p> &nbsp; &nbsp; User Name: " +
          this.brokerFname +
          " " +
          this.brokerLname +
          "</p>\
            <p> &nbsp; &nbsp; User Email: " +
          this.brokerEmail +
          "</p>\
            <p><strong>Please login to your PetersonClient account to to review.</strong> </p>",
      });
      this.dlgModalAdmin = false;
    },

    deleteBrokerItemConfirm() {
      CommonServices.deleteRecord("DealBrokers", this.deletedId)
        .then(() => {
          let index = this.selectedBrokerItems.findIndex(
            (item) => item.id === this.deletedId
          );
          this.selectedBrokerItems.splice(index, 1);
          this.getBrokerData();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          // this.logActivity(
          //   "Deleted Record",
          //   this.editedItem.client_id,
          //   JSON.stringify(this.editedItem)
          // );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },


    deleteSellerItemConfirm() {
      CommonServices.deleteRecord("DealSellers", this.deletedId)
        .then(() => {
          let index = this.selectedSellerItems.findIndex(
            (item) => item.id === this.deletedId
          );
          this.selectedSellerItems.splice(index, 1);
          this.getSellerData();
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          // this.logActivity(
          //   "Deleted Record",
          //   this.editedItem.client_id,
          //   JSON.stringify(this.editedItem)
          // );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteBuyerItemConfirm() {
      CommonServices.deleteRecord("DealBuyers", this.deletedId)
        .then(() => {
          let index = this.selectedBuyerItems.findIndex(
            (item) => item.id === this.deletedId
          );
          this.selectedBuyerItems.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          // this.logActivity(
          //   "Deleted Record",
          //   this.editedItem.client_id,
          //   JSON.stringify(this.editedItem)
          // );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteBrokerItem(id) {
      this.deletedId = id;
      this.dialogDeleteBroker = true;
    },

    deleteSellerItem(id) {
      this.deletedId = id;
      this.dialogDeleteSeller = true;
    },

    deleteBuyerItem(id) {
      this.deletedId = id;
      this.dialogDeleteBuyer = true;
    },

    closeDelete() {
      this.dialogDeleteBroker = false;
      this.dialogDeleteSeller = false;
      this.dialogDeleteBuyer = false;
    },

    closeBroker() {
      this.dialogBroker = false;
    },

    closeSeller() {
      this.dialogSeller = false;
    },

    closeBuyer() {
      this.dialogBuyer = false;
    },

    logID() {
      console.log("EditedItem :", this.editedItem);
    },

    showDialog1() {
      this.dialog = true;
      this.dialog2 = false;
    },

    showDialog2() {
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting brokers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      console.log(
        "Selected Broker Items/Length",
        this.selectedBrokerItems,
        this.selectedBrokerItems.length
      );
      if (this.selectedBrokerItems.length == 0) {
        this.dlgError = true;
        this.errorTitle = "You must first select a Broker!";
        this.errorText = "Please select a Broker for this Deal";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      this.getSellerData();
      this.dialog = false;
      this.dialog2 = true;
    },

    showDialog3() {
      this.getBuyerData();
      this.dialog2 = false;
      this.dialog3 = true;
    },

    getDealBrokers(dealID, brokerID, email) {
      console.log("BrokerID: ", brokerID);
      CommonServices.addRecord("DealBrokers", {
        deal_id: dealID,
        broker_id: brokerID,
      })
        .then((response) => {
          console.log("Selected Broker response: ", response);
          this.getSelectedBrokerData(dealID);
          this.getBrokerData();
          //now send an email to the admin for this deal
          this.brokerFname = response.first_name;
          this.brokerLname = response.last_name;
          this.brokerEmail = email;
          this.openSendAdminEmail("Broker");
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.data).forEach((key) => {
              this.snackText = error.data[key][0];
            });
          } catch (e) {
            this.snackbar = true;
            this.snackColor = "error";
            this.snackText = "Something went wrong";
          }
        });
    },

    openSendBrokerEmail(recipient) {
      this.dlgModal = true;
      this.errorTitle = "Send " + recipient + " Email?";
      this.errorText =
        "Would you like to send an email to this " +
        recipient +
        " alerting them that they have been assigned to this deal?";
      this.headerBgVariant = "success";
      this.footerBgVariant = "success";
    },

    openSendAdminEmail(ghl) {
      if(ghl !==true){
        this.dlgModalAdmin = true;
        this.errorTitle = "Send Admin Email?";
        this.errorText =
          "Would you like to send an email to the Admin of this account alerting them that they have been assigned to this deal?";
        this.headerBgVariant = "success";
        this.footerBgVariant = "success";
      }else{
        this.sendBrokerEmail();
      }

    },

    async sendBrokerEmail() {
      console.log("Sending broker email...");
      console.log("broker email", this.brokerEmail);
      await CommonServices.postData("/Twillio/send-email", {
        user_name: `${this.brokerFname || "New"} ${this.brokerLname || "User"}`,
        to_email: this.brokerEmail,
        subject: "NEW DEAL ASSIGNMENT",
        text_content: "",
        html_content:
          "<p>Dear " +
          this.brokerFname +
          " " +
          this.brokerLname +
          ",</p>\
            <p>You have just been assigned to the following Deal:</p>\
            <p> &nbsp; &nbsp; User Name: " +
          this.editedItem.deal_name +
          "</p>\
            <p><strong>Please login to your PetersonClient account to ensure all is working properly.</strong> </p>",
      });

      this.dlgModal = false;
    },

  async getDealSellers(dealID, sellerID, email, ghl) {
  console.log("Adding SellerID to DealSellers table: ", dealID, sellerID, ghl);
  try {
    const response = await CommonServices.addRecord("DealSellers", {
      deal_id: dealID,
      seller_id: sellerID,
    });
    console.log("Selected Seller response: ", response);
    await this.getSelectedSellerData(dealID, ghl);
    this.getSellerData();
    // now send an email to the admin for this deal
    this.brokerFname = response.first_name;
    this.brokerLname = response.last_name;
    this.brokerEmail = email;
    this.openSendAdminEmail("Broker",ghl);
    if(ghl==true){
      //Post the engagementItem to the EngagementAgreement endpoint
      this.saveEngagementItem();  
    }
  
  } catch (error) {
    this.snackbar = true;
    this.snackColor = "error";
    try {
      Object.keys(error.data).forEach((key) => {
        this.snackText = error.data[key][0];
      });
    } catch (e) {
      this.snackbar = true;
      this.snackColor = "error";
      this.snackText = "Something went wrong";
    }
  }
},


    getDealBuyers(dealID, buyerID, email) {
      console.log("BuyerID: ", buyerID);
      CommonServices.addRecord("DealBuyers", {
        deal_id: dealID,
        buyer_id: buyerID,
      })
        .then((response) => {
          console.log("Selected Buyer response: ", response);
          //now send an email to the admin for this deal
          this.brokerFname = response.first_name;
          this.brokerLname = response.last_name;
          this.brokerEmail = email;
          this.openSendAdminEmail("Broker");
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          try {
            Object.keys(error.data).forEach((key) => {
              this.snackText = error.data[key][0];
            });
          } catch (e) {
            this.snackbar = true;
            this.snackColor = "error";
            this.snackText = "Something went wrong";
          }
        });
    },

    selectBroker(item) {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected broker to the DealBrokers table
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting brokers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      //this.getDup();
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealBrokers(response.id, item.id, item.email);
            
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          }).finally(this.createUploads());
      } else {
        this.getDealBrokers(this.currentDealID, item.id, item.email);
      }
    },

    selectCurrentBroker() {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected broker to the DealBrokers table
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealBrokers(
              response.id,
              this.currentUserID,
              this.currentEmail
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          }).finally(this.createUploads());
      } else {
        this.getDealBrokers(
          this.currentDealID,
          this.currentUserID,
          this.currentEmail
        );
      }
    },

    selectSeller(item) {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected seller to the Dealsellers table
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting sellers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            //this.getSellerData();//refreshes list of available sellers after user selection
            this.getDealSellers(response.id, item.id, item.email);
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.getDealSellers(this.currentDealID, item.id, item.email);
      }
    },

    selectBuyer(item) {
      //if the user has not yet saved the record then save the record and use the response id
      //to add the selected Buyer to the DealBuyers table
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting Buyers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      if (this.currentDealID == 0) {
        CommonServices.addRecord(this.componentURL, this.editedItem)
          .then((response) => {
            console.log("Saved Deal response: ", response);
            localStorage.setItem("ResponseID", response.id);
            this.getAdminData(response.admin_user_id); //get admin data for sending admin email
            this.adminUserID = response.admin_user_id;
            this.currentDealID = response.id;
            this.editedItem.id = response.id;
            this.getDealBuyers(response.id, item.id, item.email);
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackbar = true;
              this.snackColor = "error";
              this.snackText = "Something went wrong";
            }
          });
      } else {
        this.getDealBuyers(this.currentDealID, item.id, item.email);
      }
    },

    clearSearch() {
      this.searchText = "";
      this.getBrokerData();
      this.getSellerData();
    },

    addBroker() {
      this.brokerItem = {};
      this.brokerItem.user_type_id = 2;
      this.brokerItem.active = true;
      this.dialogBroker = true;
    },

    addSeller() {
      this.editedSellerItem = {};
      this.editedSellerItem.user_type_id = 3;
      this.editedSellerItem.active = true;
      this.dialogSeller = true;
    },

    addBuyer() {
      this.editedItem = {};
      this.editedItem.user_type_id = 4;
      this.editedItem.active = true;
      this.dialogBuyer = true;
    },

    getBrokers() {
      CommonServices.listWithParams("Users/?user_type_id=2", this.items)
        .then((response) => {
          this.brokerItems = response.results;
          console.log("Broker Items: ", this.brokerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCurBroker() {
      CommonServices.listWithParams(
        `Users/?id=${this.currentUserID}`,
        this.items
      )
        .then((response) => {
          this.curbrokerItem = response.results;
          console.log("Current Broker Item: ", this.curbrokerItem);
          this.selectBroker(this.curbrokerItem);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSellers() {
      CommonServices.listWithParams("Users/?user_type_id=3", this.items)
        .then((response) => {
          this.sellerItems = response.results;
          console.log("Seller Items: ", this.sellerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBuyers() {
      CommonServices.listWithParams("Users/?user_type_id=4", this.items)
        .then((response) => {
          this.buyerItems = response.results;
          console.log("Buyer Items: ", this.buyerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSelectedBrokers() {
      CommonServices.listWithParams(
        `DealBrokers/?deal_id=${this.currentDealID}`,
        this.items
      )
        .then((response) => {
          this.selectedBrokerItems = response.results;
          console.log("Selected Broker Items: ", this.selectedBrokerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSelectedSellers() {
      CommonServices.listWithParams(
        `DealSellers/?deal_id=${this.currentDealID}`,
        this.items
      )
        .then((response) => {
          this.selectedSellerItems = response.results;
          console.log("Selected Seller Items: ", this.selectedSellerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSelectedBuyers() {
      CommonServices.listWithParams(
        `DealBuyers/?deal_id=${this.currentDealID}`,
        this.items
      )
        .then((response) => {
          this.selectedBuyerItems = response.results;
          console.log("Selected Buyer Items: ", this.selectedBuyerItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCities() {
      CommonServices.getList("Cities")
        .then((res) => {
          console.log("Cities", res);
          let codes = res.results;
          codes.map((code) =>
            this.list_city.push({ id: code.id, text: code.city })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getStates() {
      CommonServices.getList("States")
        .then((res) => {
          console.log("States", res);
          let codes = res.results;
          codes.map((code) =>
            this.list_states.push({ id: code.id, text: code.state })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSelectedBrokerData(dealID) {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      console.log("Getting Deal Brokers for Deal #: ", dealID);
      CommonServices.listWithParams(`DealBrokers/?deal_id=${dealID}`, {
        params: params,
      })
        .then((response) => {
          this.selectedBrokerItems = response.results;
          console.log("Selected Brokers: ", this.selectedBrokerItems);
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSelectedSellerData(dealID,ghl) {
      console.log("Getting Deal Sellers for Deal #: ", dealID,ghl);
      CommonServices.listWithParams(`DealSellers/?deal_id=${dealID}`)
        .then((response) => {
          this.selectedSellerItems = response.results;
          console.log("Selected Sellers: ", this.selectedSellerItems);
          if(ghl==true){this.close(ghl);}
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getSelectedBuyerData(dealID) {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      console.log("Getting Deal Buyers for Deal #: ", dealID);
      CommonServices.listWithParams(`DealBuyers/?deal_id=${dealID}`, {
        params: params,
      })
        .then((response) => {
          this.selectedBuyerItems = response.results;
          console.log("Selected Buyers: ", this.selectedBuyerItems);
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getBrokerData() {
      //first refresh the vw_cur_deal_brokers so that previously selected brokers will not be in the available listing
      if(this.editedItem.id){
        CommonServices.putData("Functions", {
          id: 7,
          param_one_number: this.editedItem.id,
        });
      }      
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(`vwAvailableBrokers`, { params: params })
        .then((response) => {
          this.brokerItems = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },


    getSellerData() {//update the curDealSellers so that the vwAVailableSellers properly reflects available sellers
      CommonServices.putData("Functions", {
        id: 8,
        param_one_number: this.currentDealID,
      });
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(`vwAvailableSellers`, { params: params })
        .then((response) => {
          this.sellerItems = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getBuyerData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(`Users/?user_type_id=4`, { params: params })
        .then((response) => {
          this.buyerItems = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // addBuyer(dealid) {
    //       this.Buyer.deal_id = dealid;
    //       CommonServices.addRecord("DealBuyers", this.Buyer)
    //         .then((response) => {
    //           this.items.push(response);
    //         })
    //         .catch((error) => {
    //           this.snackbar = true;
    //           this.snackColor = "error";
    //           try {
    //             Object.keys(error.data).forEach((key) => {
    //               this.snackText = error.data[key][0];
    //             });
    //           } catch (e) {
    //             this.snackText = "Something went wrong";
    //           }
    //         });
    //     },

    setInitials() {
      if (
        localStorage.getItem("first_name") &&
        localStorage.getItem("last_name")
      )
        return `${localStorage.getItem("first_name")[0]} ${
          localStorage.getItem("last_name")[0]
        }`;
      return "User";
    },
    logOut() {
      localStorage.clear();
      EventBus.$emit("toggle-nav", null);
      this.$router.push({ path: "Login" });
    },

    getDup() {
      CommonServices.getList(`Deals/?deal_name=${this.editedItem.deal_name}`)
        .then((res) => {
          console.log("Get Dup Response: ", res);
          this.dupItem = res;
          console.log("Dup Item: ", this.dupItem);
          console.log("dupCount: ", this.dupItem.count);
          localStorage.setItem("dupItemID", this.dupItem.count);
          if (this.dupItem.count > 0) {
            console.log("Deal name already exists!", this.dupItem);
            this.dlgError = true;
            this.errorTitle = "Duplicate Deal Name!";
            this.errorText = "This Deal Name already exists...";
            this.headerBgVariant = "danger";
            this.footerBgVariant = "danger";
            this.editedItem.deal_name = "";
          } else {
            //If the current user is a Broker; select that broker from the Brokers list
            if (localStorage.getItem("role") === "2") {
              console.log("Selecting current broker...");
              this.selectCurrentBroker();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDupUID(uid, targetObj, targetField) {
      try {
        const res = await CommonServices.getList(`Users/?uid=${uid}`);
        console.log("Get Dup UID Response: ", res);
        this.dupItem = res;
        console.log("Dup Item: ", this.dupItem);
        console.log("dupCount: ", this.dupItem.count);
        localStorage.setItem("dupItemID", this.dupItem.count);
        if (this.dupItem.count > 0) {
          console.log("UID already exists!", this.dupItem);
          this.dlgError = true;
          this.errorTitle = "Duplicate UID Name!";
          this.errorText = "This UID already exists...";
          this.headerBgVariant = "danger";
          this.footerBgVariant = "danger";
          this.editedSellerItem.uid = "";
          if (targetObj && targetField) {
            targetObj[targetField] = "";
          }
          return false;
        } else {
          this.brokerItem.django_pwd = this.generatePassword(10);
          this.editedSellerItem.django_pwd = this.generatePassword(10);
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },


    createUploads(dealid) {
      //First write any missing Deal Folders to the DealFolders table
      CommonServices.putData("Functions", { id: 4, param_one_number: dealid })
        .then((response) => {
          console.log("Create Deal Folders Response: ", response.id);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
      localStorage.setItem("DealID", dealid);

      //Next write any missing Uploads to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    openDeals() {
      this.$router.push({ name: "Main_data" });
      this.$router.push({ name: "DealsMain" });
      location.reload();
    },

    // Function to check for numbers
    isNumeric(inputtxt) {
      var Number = /^[0-9]+$/;
      if (inputtxt.match(Number)) {
        return true;
      } else {
        return false;
      }
    },

    dialogOpen() {
      this.currentDealID = 0;
      this.getBrokers();
      this.getSellers();
      this.getBuyers();
      this.selectedBrokerItems = [];
      this.selectedSellerItems = [];
      this.selectedBuyerItems = [];
      console.log("Admin/Id", this.isAdmin, this.currentUserID);
      if (this.isAdmin === "true") {
        console.log("Setting Admin ID...");
        this.editedItem.admin_user_id = this.currentUserID;
      } else {
        console.log("Setting Default Admin...");
        this.editedItem.admin_user_id = 43;
      }
      this.dialog = true;
      //this.change_step(1)
    },

    resetItems() {
      console.log("Resetting items...");
      this.editedItem = {
        id: "",
        deal_name: "",
        admin_user_id: "",
        broker_id: "",
        seller_id: "",
        buyer_id: "",
        city: "",
        state_id: "",
      };
    },

    validateDeal() {
      this.getDup(this.editedItem.deal_name);
    },

    saveFormData() {
      if (!this.editedItem.deal_name) {
        this.dlgError = true;
        this.errorTitle = "You must first specify a Deal Name!";
        this.errorText =
          "Please specify a name for this Deal prior to selecting brokers...";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }
      console.log(
        "Selected Broker Items/Length",
        this.selectedBrokerItems,
        this.selectedBrokerItems.length
      );
      if (this.selectedBrokerItems.length == 0) {
        this.dlgError = true;
        this.errorTitle = "You must first select a Broker!";
        this.errorText = "Please select a Broker for this Deal";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";
        return;
      }

      if (this.selectedBrokerItems.length > 0) {
            if (this.selectedSellerItems.length == 0) {
              this.dlgError = true;
              this.errorTitle = "You must first select a Seller!";
              this.errorText = "Please select a Seller for this Deal";
              this.headerBgVariant = "danger";
              this.footerBgVariant = "danger";
              return;
            }          
          } 

      if (this.$refs.form.validate()) {
        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL, this.editedItem)
            .then((response) => {
              console.log("SaveFormData Response", response);
              this.openDeals();
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.openDeals();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
      }
    },

    async saveBrokerFormData() {
      if (this.$refs.form.validate()) {
        if (this.brokerItem.id) {
          this.brokerItem.id=this.brokerItem.broker_id //since we're putting from a User record use broker id
          await CommonServices.updateData("Users", this.brokerItem)
            .then((response) => {
              const index = this.selectedBrokerItems.findIndex(
                (item) => item.id === response.id
              );
              //window.alert('Got here...')
              this.getSelectedBrokerData(this.brokerItem.deal_id);
              this.getBrokerData();
              this.$set(this.selectedBrokerItems, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Error in saveBrokerFormData() ";
              }
            });
        } else {
          CommonServices.addRecord("Users", this.brokerItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.getBrokerData();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Error adding record in saveBrokerFormData()";
              }
            });
        }
        this.dialogBroker = false;
      }
    },

    saveSellerFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedSellerItem.id) {
          CommonServices.updateData("Users", this.editedSellerItem)
            .then((response) => {
              const index = this.editedSellerItem.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.editedSellerItem, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Error updating record in saveSellerFormData()";
              }
            });
        } else {
          CommonServices.addRecord("Users", this.editedSellerItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.getSellerData();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Something went wrong";
              }
            });
        }
        this.dialogSeller = false;
      }
    },

    async checkForDupUID() {
      if (!(await this.getDupUID(this.ghlSellerItem.uid, this.ghlSellerItem, 'uid'))) {
        return false;
      }
      return true;
    },

    saveGHLFormData() {
      if (!this.ghlSellerItem.uid || !this.ghlSellerItem.django_pwd){
        this.dlgUID=true
        this.errorTitle = "Missing UID/Password";
        this.errorText =
          "You must first assign a UID AND a Password to this Seller before you can save the record";
        this.headerBgVariant = "danger";
        this.footerBgVariant = "danger";        
        return false}

      //   this.checkForDupUID().then((result) => {
      //     window.alert ('Result: ', result)
      //     if (!result) {
      //       window.alert('Result is false')
      //       return false;
      //     } else {
      //       window.alert('No dups...');
      //       // rest of the code
      //     }
      //   });

      // window.alert('Got pass DupID check')
      this.engagementItem.deal_id=this.currentDealID;
      this.engagementItem.company_name=this.ghlSellerItem.company;
      this.engagementItem.primary_seller_first_name=this.ghlSellerItem.company;
      this.engagementItem.primary_seller_last_name=this.ghlSellerItem.company;
      this.engagementItem.engagement_start_date=this.$moment(String(Date())).format('MM/DD/YYYY');
      console.log('Engagement Item:',this.engagementItem)     
          CommonServices.addRecord("Users", this.ghlSellerItem)
            .then((response) => {
              console.log("Saved GHL User item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.getSellerData(response.id);              
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Error adding GHL record";
              }
            });

        this.dlgGHL = false;
      },

    saveBuyerFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id) {
          CommonServices.updateData("Users", this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.locaton.reload();
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord("Users", this.editedItem)
            .then((response) => {
              console.log("Saved item: ", response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
              this.getBuyerData();
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackbar = true;
                this.snackColor = "error";
                this.snackText = "Something went wrong";
              }
            });
        }
        this.dialogBuyer = false;
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
          if (this.exportRecord) this.exportdata();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.error = "Something went wrong";
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getAdminData(adminID) {
      CommonServices.getList(`Users/${adminID}`)
        .then((res) => {
          this.adminItem = res.results;
          console.log("Admin Res: ", res);
          console.log("Deal Admin: ", res.first_name, res.last_name, res.email);
          this.adminFname = res.first_name;
          this.adminLname = res.last_name;
          this.adminEmail = res.email;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editedItems(item,editType) {
      if(editType=='broker'){
        this.brokerItem = Object.assign({}, item);
        this.brokerItem.user_type_id=2;
        this.brokerItem.city_id=parseInt(this.brokerItem.city_id);
        this.brokerItem.state_id=parseInt(this.brokerItem.state_id);       
        this.dialogBroker = true;}
      else if(editType=='seller'){
        this.editedSellerItem = Object.assign({}, item);
        this.editedSellerItem.id=this.editedSellerItem.seller_id;
        this.editedSellerItem.user_type_id=3;
        this.editedSellerItem.city_id=parseInt(this.editedSellerItem.city_id);
        this.editedSellerItem.state_id=parseInt(this.editedSellerItem.state_id);       
        this.dialogSeller = true; 
        console.log('editedSellerItem: ',this.editedSellerItem)       
      }else{
        this.editedItem = Object.assign({}, item);
        this.dialog = true;        
      }

    },

    closeGHL(){
      this.dlgGHL=false;
    },

    close(ghl) {
      if(ghl==true){
          console.log('Engagement Agreement; continue')
        }else {     
          if (this.selectedBrokerItems.length > 0) {
            if (this.selectedSellerItems.length == 0) {
              this.dlgError = true;
              this.errorTitle = "You must first select a Seller!";
              this.errorText = "Please select a Seller for this Deal";
              this.headerBgVariant = "danger";
              this.footerBgVariant = "danger";
              return;
            }          
          }      
      }

      console.log(
        "Selected Seller Items/Length",
        this.selectedSellerItems,
        this.selectedSellerItems.length
      );

      this.resetItems();
      this.dialog = false;
      this.dialog2 = false;
      this.dialog3 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
        location.reload();
        if (ghl==true) {
          this.$router.push(`/EngagementAgreement/${this.currentDealID}`);
        }
      });
    },
  },
};
</script>

<style  scoped>
.engage-button {
  color: white;
  background-color: #0157a0;
  height: 36px;
  width: auto;
  border-radius: 2px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 15px;
  cursor: pointer;
}

.pop-up-header {
  background-color: lightblue;
  text-align: center;
  font-size: larger;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  margin-top: -40px;
  padding-top: 10px;
  height: 50px;
}

.input-standard {
  font-size:small; 
  font-family: "Lato", sans-serif;
  height:28px;
  padding-right: 5px;
  padding-left: 5px; 
}

.wizard-button {
  background-color: rgba(145, 9, 31, 255);
  border: none;
  margin-left: 300px;
  margin-right: 0px;
  margin-top: 200px;
  width: auto;
  border-width: 1px;
  border-radius: 20;
}

.wizard-button-group {
  background-color: rgba(145, 9, 31, 255);
  border: none;
  margin-right: 0px;
  width: auto;
  border-width: 1px;
  border-radius: 20;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
}

.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  border-radius: 8px;
  border-radius: 18px;
}

.add-button {
  background-color: rgba(81, 109, 131, 255);
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.save-button-ghl {
  background-color: rgb(9, 63, 9);
  color:#bcc3ca;
  font-family: Lato, sans-serif;
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  width: 100px;
  margin-right: 360px;
  margin-left: 10px;
}
.engage-button-ghl {
  background-color: rgb(17, 3, 68);
  color:#bcc3ca;
  font-family: Lato, sans-serif;
  border-color: rgba(81, 109, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  width: 220px;
  position: absolute;
  right: 0;
  margin-right: 20px;  
}

.save-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  border-radius: 8px;
  border-radius: 18px;
}

.next-button {
  background-color: blue;
  border: none;
  border-radius: 18px;
  border-radius: 8px;
  width: 90px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  width: 130px;
}

.datatable-button {
  background-color: rgb(3, 51, 3);
  height: 35px;
  width: auto;
  margin-left: 57px;
  margin-right: 0px;
  font-size: 12px;
  text-align: center;
}

.err-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: red;
  line-height: 0pt;
}

.gradeText .v-input__control .v-input__slot .v-text-field__slot input {
  color: red !important;
}

.header-add-location {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0091ff !important;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px !important;
  border-radius: 60px;
}

.v-responsive {
  flex: none !important;
}

.v-btn--contained {
  box-shadow: none !important;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 12px !important;
}

.text-white {
  color: white !important;
  font-size: 15px;
}

.v-avatar {
  float: right;
}

.mb-p p {
  margin-bottom: 0 !important;
}

.plus-css {
  margin-right: 55px;
  width: 123px;
}

.v-toolbar__items {
  height: initial !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: unset !important;
}

.footer-custome-css {
  margin-top: -50px;
  text-align: center;
  background-color: transparent !important;
}

.search {
  border-radius: 10px;
}

.header-search > div {
  display: block !important;
  width: 100% !important;
}

.header-menu {
  margin-left: -114px;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 36px !important;
}

.header-search .v-input__slot {
  margin: 0 !important;
  border-radius: 55px !important;
  background-color: #dcdcdc !important;
}

.v-avatar {
  overflow: inherit !important;
}

.icon_css {
  margin-left: 13px !important;
}

.header-search .v-text-field__details {
  display: none;
}

.header-inner > .v-toolbar__content {
  justify-content: space-around;
}

.header-inner .header-menu button {
  margin: 0 !important;
}

.Cassell-buttons .v-btn {
  color: gray !important;
  text-transform: lowercase !important;
}

.main-header .v-application--wrap {
  min-height: auto !important;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.09);
}

.top-banner-wrap .v-image__image {
  background-size: cover;
}

.main-footer {
  width: 100%;
  box-shadow: none !important;
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0 !important;
  background-color: transparent !important;
}

.main-footer div {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-right {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}

.header-left > div,
.header-right > div {
  padding: 0 5px;
}

.header-options ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.header-options ul ul {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  top: 90%;
  padding: 15px 0px !important;
  background: #fff;
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.header-search .v-input__slot {
  box-shadow: none !important;
  background-color: rgba(170, 177, 185, 0.25) !important;
}

.header-options > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
}

.header-options > ul > li {
  position: relative;
}

.header-options ul ul a {
  font-size: 15px;
  line-height: 22px;
  padding: 5px 15px;
  color: #7e8594;
}

.header-options ul ul a:hover {
  background: #eeeeee;
}

.header-options ul ul a span {
  margin-left: 10px;
}

.header-options > ul {
  display: flex;
  flex-wrap: wrap;
}

.header-options a {
  font-family: "Poppins", sans-serif;
}

.header-options > ul > li > a {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  padding: 28px 0;
  display: flex;
  align-items: center;
  color: #7e8594;
}

.header-options > ul > li > a img {
  display: none;
}

a {
  text-decoration: none;
}

.header-options > ul > li {
  margin: 0 13px;
}

.header-logo {
  max-width: 180px;
}

.header-options ul ul a {
  display: flex;
  align-items: center;
  position: relative;
}

.header-options > ul > li > a i {
  margin-left: 9px;
}

.header-add {
  max-width: 140px;
}

.header-add a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0091ff;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

.v-dialog {
  width: 80% !important;
}

.customer-next-btn {
  background: #0157a0;
  box-shadow: 0px 5px 10px rgba(110, 115, 243, 0.29);
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff !important;
  padding: 18px 75px;
}

.customer-clear-btn {
  background: #0157a0;
  box-shadow: 0px 5px 10px rgba(110, 115, 243, 0.29);
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff !important;
  padding: 16px 50px;
}

.popup-wrap {
  padding: 40px 2px;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}

.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}

.close-popup.v-btn > span + span {
  display: none !important;
}

.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}

.close-popup.v-btn:before {
  content: none;
}

.back-popup {
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
}

.customer-form label,
.customer-form textarea {
  font-size: 14px !important;
}

/*.popup-wrap-inner.step-three .customer-search-table .v-data-footer {*/
/*    width: 40%;*/
/*    justify-content: flex-start;*/
/*    position: relative;*/
/*    bottom: -60px;*/
/*}*/

.ac-check-box ul {
  margin: 0;
  padding: 0 !important;
  list-style: none;
}

.ac-check-box ul li {
  display: inline-block;
}

.ac-check-box ul li > div {
  margin: 0;
  padding: 0;
}

.ac-check-box ul li .v-input__slot label {
  margin-right: 4px !important;
}

.ac-check-box ul li .v-input__slot > div {
  order: 2;
}

.ac-check-box ul li + li {
  margin-left: 15px;
}

.ac-check-box ul li i {
  color: #bcc3ca !important;
}

.phone-check > span {
  position: absolute;
  top: 0;
  right: 15px;
}

.phone-check {
  position: relative;
}

.map-box {
  position: relative;
}

.map-box > span {
  position: absolute;
  right: 15px;
  bottom: 21px;
}

.header-add > div {
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
}

/*******/
.user-avtar {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 23px 0;
}

.header-options ul {
  padding-left: 0 !important;
}

.user-option {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 8;
  top: 100%;
  padding: 15px 0px !important;
  background: #fff;
  box-shadow: 0px 12px 27px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  min-width: 165px;
  right: 0;
}

.user-option ul {
  padding: 0 !important;
  margin: 0;
  list-style: none;
}

.header-user {
  position: relative;
}

.user-option a {
  font-size: 15px;
  line-height: 22px;
  color: #7e8594 !important;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  position: relative;
}

.user-option a span {
  margin-left: 10px;
}

.user-option a:hover {
  background-color: #eeeeee;
}

.user-option.active {
  opacity: 1;
  visibility: visible;
}

span.close-icon {
  display: none;
}

.user-avtar img {
  width: 35px;
  border-radius: 100%;
  margin-right: 10px;
}

.headline {
  background-color: red;
  color: white;
}

.header-search {
  width: 60%;
}

@media only screen and (max-width: 1903px) {
  .header-left {
    width: 59%;
  }

  .header-right {
    width: 37%;
  }
}

@media only screen and (max-width: 1263px) {
  .header-options > ul > li > a {
    padding: 5px 0;
  }

  .header-left {
    width: 38%;
  }

  .header-right {
    width: 62%;
    justify-content: space-between;
  }

  .header-options ul ul {
    right: 0;
    pointer-events: none;
  }

  .main-header .v-application--wrap {
    padding: 15px 0;
  }

  .header-logo {
    margin-bottom: 10px;
  }

  .user-avtar {
    padding: 4px 0;
  }

  .header-options::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  .header-options::-webkit-scrollbar-track {
    background: #d9d9d9;
  }

  .header-options > ul > li > a img {
    display: inline-block;
    width: 16px;
    margin-right: 15px;
  }

  /* Handle */
  .header-options::-webkit-scrollbar-thumb {
    background: #7e8594;
  }

  .header-options {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 !important;
    background: #eeeeee;
    z-index: 9;
    height: 100vh;
    overflow-y: scroll;
    width: 59px;
    transition: all 0.3s ease 0s;
    overflow-x: hidden;
  }

  .header-options > ul > li:hover ul {
    opacity: 0;
    visibility: hidden;
  }

  .header-options > ul > li {
    display: block;
    width: 100%;
    margin: 0;
  }

  .header-options ul ul {
    left: 0;
    padding: 10px 5px !important;
  }

  .header-options > ul {
    height: 100%;
    min-width: 270px;
    display: block;
  }

  .header-options:hover {
    width: 270px !important;
  }

  .header-options > ul > li a {
    position: relative;
    font-size: 14px;
  }

  .header-options > ul > li > a {
    color: rgba(0, 0, 0, 0.85);
    padding: 10px 20px;
  }

  .header-options > ul > li > a i {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .close-icon {
    display: block !important;
    text-align: right;
    margin: 20px 20px 10px 20px;
  }

  .header-options ul ul a span {
    opacity: 0;
    visibility: hidden;
  }

  .header-options ul ul.active {
    opacity: 1 !important;
    visibility: visible !important;
    position: relative;
    pointer-events: all;
    border-radius: 0;
    box-shadow: none;
  }

  .header-options > ul > li > a > span {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
  }

  .header-options:hover > ul > li > a > span {
    opacity: 1;
    visibility: visible;
  }

  .header-options:hover ul ul a span {
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 991px) {
  .v-application .add-customer-popup form > div > div {
    padding: 0 20px !important;
  }

  .header-left {
    width: 20%;
  }

  .header-right {
    width: 80%;
    justify-content: flex-end;
  }

  .v-application .add-customer-popup form > div > div > div:first-child {
    padding-right: 15px !important;
  }

  .v-application .add-customer-popup form > div > div > div:nth-child(2) {
    padding-left: 15px !important;
  }

  .v-application .add-customer-popup form > div > div > div:last-child {
    margin: 10px 0 0 0 !important;
  }

  .customer-next-btn {
    padding: 12px 50px;
  }

  .popup-title h6 {
    font-size: 22px;
  }

  .popup-wrap-inner.step-three .customer-search-table .v-data-footer {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .header-options ul ul {
    right: 0;
  }

  .header-options > ul {
    justify-content: center;
    margin: 0 0 10px 0;
  }

  .header-logo {
    width: 100% !important;
    max-width: 100%;
  }

  .header-search {
    width: 200px;
  }

  .header-left {
    width: 30%;
  }

  .header-right {
    width: 70%;
    justify-content: center;
  }
}

@media only screen and (max-width: 599px) {
  .customer-form form > div > div > div,
  .customer-form form > div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .popup-title {
    padding: 0;
  }

  .customer-next-btn {
    padding: 9px 20px;
    font-size: 14px;
  }

  /*.header-search {*/
  /*    width: 100%;*/
  /*    margin-bottom: 15px;*/
  /*}*/
  .v-application .popup-title {
    margin-bottom: 5px !important;
  }

  .header-options > ul > li > a {
    font-size: 14px;
  }

  .ac-check-box ul li {
    width: 100%;
    margin: 0 !important;
  }

  .back-popup img {
    width: 20px;
  }

  .close-popup .v-image__image {
    width: 20px !important;
    object-fit: contain !important;
    background-size: contain !important;
  }

  .v-application .add-customer-popup form > div > div {
    padding: 0 10px !important;
  }

  .popup-wrap-inner.step-three .customer-search-table .v-data-footer {
    width: 100% !important;
    bottom: 0 !important;
  }

  .popup-wrap-inner.step-three .customer-search-table .v-data-footer {
    width: 100% !important;
    bottom: 0 !important;
    margin-top: 0 !important;
  }

  .cn-btn-wrap > div:first-child {
    display: none;
  }

  .v-application .cn-btn-wrap > div:last-child {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left !important;
    margin: 0 !important;
  }

  .popup-title h6 {
    font-size: 20px;
    line-height: normal;
  }
}

@media only screen and (max-width: 480px) {
  .header-search {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>

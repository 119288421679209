<template>
  <div>
    <Navigation v-if="$route.name != 'Login'" />
    <router-view></router-view>
    <Footer v-if="$route.name != 'Login'" />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
};
</script>

import { evaluation_routes } from "./evaluation";
import { foreman_routes } from "./Foreman";
import defaultLayout from "@/layouts/default.vue";
import wizardLayout from "@/layouts/wizard.vue";

export const routes = [
  {
    path: "",
    component: defaultLayout,
    children: [
      {
        path: "/Login",
        name: "Login",
        component: () => import("./../components/Login"),
      },
      {
        path: "/_TestPost",
        name: "_TestPost",
        component: () => import("./../components/_TestPost"),
      },  
      {
        path: "/_Test_GHL_API",
        name: "_Test_GHL_API",
        component: () => import("./../components/_Test_GHL_API"),
      },         
      {
        path: "/EngagementAgreement/:id",
        name: "EngagementAgreement",
        component: () => import("./../components/EngagementAgreement"),
      },     
      {
        path: "/wizCashFlow_1/:id",
        name: "wizCashFlow_1",
        component: () => import("./../components/wizCashFlow_1"),
      },
      {
        path: "/MultipleCalculator/:id",
        name: "MultipleCalculator",
        component: () => import("./../components/MultipleCalculator"),
      },
      {
        path: "/MultipleCalculatorDIY/:id",
        name: "MultipleCalculatorDIY",
        component: () => import("./../components/MultipleCalculatorDIY"),
      },
      {
        path: "/CashFlowCalculator/:id",
        name: "CashFlowCalculator",
        component: () => import("./../components/CashFlowCalculator"),
      },
      {
        path: "/CashFlowCalculatorDIY/:id",
        name: "CashFlowCalculatorDIY",
        component: () => import("./../components/CashFlowCalculatorDIY"),
      },

      {
        path: "/CBRWorksheet/:id",
        name: "CBRWorksheet",
        component: () => import("./../components/CBRWorksheet"),
      },
      {
        path: "/CBRWorksheetDIY/:id",
        name: "CBRWorksheetDIY",
        component: () => import("./../components/CBRWorksheetDIY"),
      },
      {
        path: "/BlindAdSummary",
        name: "BlindAdSummary",
        component: () => import("./../components/BlindAdSummary"),
      },
      {
        path: "/BlindAdSummaryPDF",
        name: "BlindAdSummaryPDF",
        component: () => import("./../components/BlindAdSummaryPDF"),
      },
      {
        path: "/Contact",
        name: "Contact",
        component: () => import("./../components/Contact"),
      },
      {
        path: "/CreateAccount",
        name: "CreateAccount",
        component: () => import("./../components/CreateAccount"),
      },
      {
        path: "/StateMaster",
        name: "StateMaster",
        component: () => import("./../components/StateMaster"),
      },
      {
        path: "/Worksheets",
        name: "Worksheets",
        component: () => import("./../components/Worksheets"),
      },
      {
        path: "/rptOfferHistory",
        name: "rptOfferHistory",
        component: () => import("./../components/rptOfferHistory"),
      },
      {
        path: "/rptOfferDispo",
        name: "rptOfferDispo",
        component: () => import("./../components/rptOfferDispo"),
      },
      {
        path: "/rptUserAccessByDeal",
        name: "rptUserAccessByDeal",
        component: () => import("./../components/rptUserAccessByDeal"),
      },
      {
        path: "/rptUserActivityLog",
        name: "rptUserActivityLog",
        component: () => import("./../components/rptUserActivityLog"),
      },
      {
        path: "/rptLastUserActivity",
        name: "rptLastUserActivity",
        component: () => import("./../components/rptLastUserActivity"),
      },
      {
        path: "/rptOpenUploadsDeal",
        name: "rptOpenUploadsDeal",
        component: () => import("./../components/rptOpenUploadsDeal"),
      },
      {
        path: "/rptOpenUploadsBroker",
        name: "rptOpenUploadsBroker",
        component: () => import("./../components/rptOpenUploadsBroker"),
      },
      {
        path: "/DealsMain",
        name: "DealsMain",
        component: () => import("./../components/DealsMain"),
      },
      {
        path: "/DealsArchive",
        name: "DealsArchive",
        component: () => import("./../components/DealsArchive"),
      },
      {
        path: "/DealFolders/:id",
        name: "DealFolders",
        component: () => import("./../components/DealFolders"),
      },
      {
        path: "/DealOffers/:id",
        name: "DealOffers",
        component: () => import("./../components/DealOffers"),
      },
      {
        path: "/Uploads/:id",
        name: "Uploads",
        component: () => import("./../components/Uploads"),
      },
      {
        path: "/Tasks/:id",
        name: "Tasks",
        component: () => import("./../components/Tasks"),
      },
      {
        path: "/BlindAdWorksheet/:id",
        name: "BlindAdWorksheet",
        component: () => import("./../components/BlindAdWorksheet"),
      },
      {
        path: "/UploadsTemplate",
        name: "UploadsTemplate",
        component: () => import("./../components/UploadsTemplate"),
      },
      {
        path: "/DealFoldersTemplate",
        name: "DealFoldersTemplate",
        component: () => import("./../components/DealFoldersTemplate"),
      },
      {
        path: "/FolderPermissions",
        name: "FolderPermissions",
        component: () => import("./../components/FolderPermissions"),
      },
      {
        path: "/ForgotPassword",
        name: "ForgotPassword",
        component: () => import("./../components/ForgotPassword"),
      },

      {
        path: "/",
        name: "MainData",
        component: () => import("./../components/Main_data"),
      },

      {
        path: "/Reports",
        name: "Reports",
        component: () => import("./../components/Reports"),
      },

      {
        path: "/MyProfile",
        name: "MyProfile",
        component: () => import("./../components/MyProfile"),
      },
      {
        path: "/SystemAdmin",
        name: "SystemAdmin",
        component: () => import("./../components/SystemAdmin"),
      },

      {
        path: "/Help",
        name: "Help",
        component: () => import("./../components/Help"),
      },
      {
        path: "/User",
        name: "User",
        component: () => import("./../components/User"),
      },

      {
        path: "/Evaluation",
        component: () => import("./../components/Evaluation/EvaluationWrapper"),
        children: evaluation_routes,
      },
      {
        path: "/Foreman",
        component: () => import("./../components/Foreman/ForemanWrapper"),
        children: foreman_routes,
      },
      {
        path: "/Folders",
        name: "Folders",
        component: () => import("./../components/Folders"),
      },
      /***********new routes*****************/
      {
        path: "/Photos_pdf",
        name: "Photos_pdf",
        component: () => import("./../components/Photos_pdf"),
      },
      {
        path: "/CityMaster",
        name: "CityMaster",
        component: () => import("./../components/CityMaster"),
      },
    ],
  },
  {
    path: "",
    component: wizardLayout,
    children: [
      {
        path: "/DIYWelcome/:id",
        name: "DIYWelcome",
        component: () => import("./../components/DIYWelcome"),
      },
      {
        path: "/DIYIntroVideo/:id",
        name: "DIYIntroVideo",
        component: () => import("./../components/DIYIntroVideo"),
      },
      {
        path: "/CBR_1/:id",
        name: "CBR_1",
        component: () => import("./../components/CBR_1"),
      },
      {
        path: "/CBR_2/:id",
        name: "CBR_2",
        component: () => import("./../components/CBR_2"),
      },
      {
        path: "/CBR_3/:id",
        name: "CBR_3",
        component: () => import("./../components/CBR_3"),
      },
      {
        path: "/CBR_4/:id",
        name: "CBR_4",
        component: () => import("./../components/CBR_4"),
      },
      {
        path: "/CBR_5/:id",
        name: "CBR_5",
        component: () => import("./../components/CBR_5"),
      },
      {
        path: "/CBR_6/:id",
        name: "CBR_6",
        component: () => import("./../components/CBR_6"),
      },
      {
        path: "/CBR_7/:id",
        name: "CBR_7",
        component: () => import("./../components/CBR_7"),
      },
      {
        path: "/CBR_8/:id",
        name: "CBR_8",
        component: () => import("./../components/CBR_8"),
      },
      {
        path: "/CBR_9/:id",
        name: "CBR_9",
        component: () => import("./../components/CBR_9"),
      },
      {
        path: "/CBR_10/:id",
        name: "CBR_10",
        component: () => import("./../components/CBR_10"),
      },
      {
        path: "/CBR_11/:id",
        name: "CBR_11",
        component: () => import("./../components/CBR_11"),
      },
      {
        path: "/CBR_12/:id",
        name: "CBR_12",
        component: () => import("./../components/CBR_12"),
      },
      {
        path: "/CBR_13/:id",
        name: "CBR_13",
        component: () => import("./../components/CBR_13"),
      },
      {
        path: "/CBR_14/:id",
        name: "CBR_14",
        component: () => import("./../components/CBR_14"),
      },
      {
        path: "/CBR_15/:id",
        name: "CBR_15",
        component: () => import("./../components/CBR_15"),
      },
      {
        path: "/CBR_16/:id",
        name: "CBR_16",
        component: () => import("./../components/CBR_16"),
      },
      {
        path: "/DIY_1-3/:id",
        name: "DIY_1-3",
        component: () => import("./../components/DIY_1-3"),
      },
      {
        path: "/DIY_1-4/:id",
        name: "DIY_1-4",
        component: () => import("./../components/DIY_1-4"),
      },
      {
        path: "/DIY_1-5/:id",
        name: "DIY_1-5",
        component: () => import("./../components/DIY_1-5"),
      },
      {
        path: "/DIY_1-6/:id",
        name: "DIY_1-6",
        component: () => import("./../components/DIY_1-6"),
      },
      {
        path: "/DIY_1-7/:id",
        name: "DIY_1-7",
        component: () => import("./../components/DIY_1-7"),
      },

      {
        path: "/DIY_1-11/:id",
        name: "DIY_1-11",
        component: () => import("./../components/DIY_1-11"),
      },
      {
        path: "/DIY_1-12/:id",
        name: "DIY_1-12",
        component: () => import("./../components/DIY_1-12"),
      },
      {
        path: "/DIY_1-13/:id",
        name: "DIY_1-13",
        component: () => import("./../components/DIY_1-13"),
      },
      {
        path: "/DIY_2-1/:id",
        name: "DIY_2-1",
        component: () => import("./../components/DIY_2-1"),
      },
      {
        path: "/DIY_2-2/:id",
        name: "DIY_2-2",
        component: () => import("./../components/DIY_2-2"),
      },
      {
        path: "/DIY_2-3/:id",
        name: "DIY_2-3",
        component: () => import("./../components/DIY_2-3"),
      },
      {
        path: "/DIY_2-4/:id",
        name: "DIY_2-4",
        component: () => import("./../components/DIY_2-4"),
      },
      {
        path: "/DIY_2-5/:id",
        name: "DIY_2-5",
        component: () => import("./../components/DIY_2-5"),
      },
      {
        path: "/DIY_2-6/:id",
        name: "DIY_2-6",
        component: () => import("./../components/DIY_2-6"),
      },
      {
        path: "/DIY_2-7/:id",
        name: "DIY_2-7",
        component: () => import("./../components/DIY_2-7"),
      },
      {
        path: "/DIY_2-8/:id",
        name: "DIY_2-8",
        component: () => import("./../components/DIY_2-8"),
      },
      {
        path: "/DIY_2-9/:id",
        name: "DIY_2-9",
        component: () => import("./../components/DIY_2-9"),
      },
      {
        path: "/DIY_2-10/:id",
        name: "DIY_2-10",
        component: () => import("./../components/DIY_2-10"),
      },
      {
        path: "/DIY_2-11/:id",
        name: "DIY_2-11",
        component: () => import("./../components/DIY_2-11"),
      },
      {
        path: "/DIY_2-12/:id",
        name: "DIY_2-12",
        component: () => import("./../components/DIY_2-12"),
      },
      {
        path: "/DIY_2-13/:id",
        name: "DIY_2-13",
        component: () => import("./../components/DIY_2-13"),
      },

      {
        path: "/DIY_3-1/:id",
        name: "DIY_3-1",
        component: () => import("./../components/DIY_3-1"),
      },
      {
        path: "/DIY_3-2/:id",
        name: "DIY_3-2",
        component: () => import("./../components/DIY_3-2"),
      },
      {
        path: "/DIY_3-3/:id",
        name: "DIY_3-3",
        component: () => import("./../components/DIY_3-3"),
      },
      {
        path: "/DIY_3-4/:id",
        name: "DIY_3-4",
        component: () => import("./../components/DIY_3-4"),
      },
      {
        path: "/DIY_3-5/:id",
        name: "DIY_3-5",
        component: () => import("./../components/DIY_3-5"),
      },
      {
        path: "/DIY_3-6/:id",
        name: "DIY_3-6",
        component: () => import("./../components/DIY_3-6"),
      },
      {
        path: "/DIY_3-7/:id",
        name: "DIY_3-7",
        component: () => import("./../components/DIY_3-7"),
      },
      {
        path: "/DIY_3-8/:id",
        name: "DIY_3-8",
        component: () => import("./../components/DIY_3-8"),
      },
      {
        path: "/DIY_3-9/:id",
        name: "DIY_3-9",
        component: () => import("./../components/DIY_3-9"),
      },
      {
        path: "/DIY_3-10/:id",
        name: "DIY_3-10",
        component: () => import("./../components/DIY_3-10"),
      },
      {
        path: "/DIY_3-11/:id",
        name: "DIY_3-11",
        component: () => import("./../components/DIY_3-11"),
      },
      {
        path: "/DIY_3-12/:id",
        name: "DIY_3-12",
        component: () => import("./../components/DIY_3-12"),
      },
      {
        path: "/DIY_3-13/:id",
        name: "DIY_3-13",
        component: () => import("./../components/DIY_3-13"),
      },
      {
        path: "/DIY_3-14/:id",
        name: "DIY_3-14",
        component: () => import("./../components/DIY_3-14"),
      },
      {
        path: "/DIY_3-15/:id",
        name: "DIY_3-15",
        component: () => import("./../components/DIY_3-15"),
      },
      {
        path: "/DIY_3-16/:id",
        name: "DIY_3-16",
        component: () => import("./../components/DIY_3-16"),
      },
      {
        path: "/DIY_4-1/:id",
        name: "DIY_4-1",
        component: () => import("./../components/DIY_4-1"),
      },
      {
        path: "/DIY_4-2/:id",
        name: "DIY_4-2",
        component: () => import("./../components/DIY_4-2"),
      },
      {
        path: "/DIY_4-3/:id",
        name: "DIY_4-3",
        component: () => import("./../components/DIY_4-3"),
      },
      {
        path: "/DIY_4-4/:id",
        name: "DIY_4-4",
        component: () => import("./../components/DIY_4-4"),
      },
      {
        path: "/DIY_4-5/:id",
        name: "DIY_4-5",
        component: () => import("./../components/DIY_4-5"),
      },
      {
        path: "/DIY_4-6/:id",
        name: "DIY_4-6",
        component: () => import("./../components/DIY_4-6"),
      },
      {
        path: "/DIY_4-7/:id",
        name: "DIY_4-7",
        component: () => import("./../components/DIY_4-7"),
      },
      {
        path: "/DIY_4-8/:id",
        name: "DIY_4-8",
        component: () => import("./../components/DIY_4-8"),
      },
      {
        path: "/DIY_1-8/:id",
        name: "DIY_1-8",
        component: () => import("./../components/DIY_1-8"),
      },
      {
        path: "/DIY_1-9/:id",
        name: "DIY_1-9",
        component: () => import("./../components/DIY_1-9"),
      },
      {
        path: "/DIY_1-10/:id",
        name: "DIY_1-10",
        component: () => import("./../components/DIY_1-10"),
      },
    ],
  },

  {
    path: "/DIY_1-11/:id",
    name: "DIY_1-11",
    component: () => import("./../components/DIY_1-11"),
  },
  {
    path: "/DIY_1-12/:id",
    name: "DIY_1-12",
    component: () => import("./../components/DIY_1-12"),
  },
  {
    path: "/DIY_1-13/:id",
    name: "DIY_1-13",
    component: () => import("./../components/DIY_1-13"),
  },
  {
    path: "/DIY_2-1/:id",
    name: "DIY_2-1",
    component: () => import("./../components/DIY_2-1"),
  },
  {
    path: "/DIY_2-2/:id",
    name: "DIY_2-2",
    component: () => import("./../components/DIY_2-2"),
  },
  {
    path: "/DIY_2-3/:id",
    name: "DIY_2-3",
    component: () => import("./../components/DIY_2-3"),
  },
  {
    path: "/DIY_2-4/:id",
    name: "DIY_2-4",
    component: () => import("./../components/DIY_2-4"),
  },
  {
    path: "/DIY_2-5/:id",
    name: "DIY_2-5",
    component: () => import("./../components/DIY_2-5"),
  },
  {
    path: "/DIY_2-6/:id",
    name: "DIY_2-6",
    component: () => import("./../components/DIY_2-6"),
  },
  {
    path: "/DIY_2-7/:id",
    name: "DIY_2-7",
    component: () => import("./../components/DIY_2-7"),
  },
  {
    path: "/DIY_2-8/:id",
    name: "DIY_2-8",
    component: () => import("./../components/DIY_2-8"),
  },
  {
    path: "/DIY_2-9/:id",
    name: "DIY_2-9",
    component: () => import("./../components/DIY_2-9"),
  },
  {
    path: "/DIY_2-10/:id",
    name: "DIY_2-10",
    component: () => import("./../components/DIY_2-10"),
  },
  {
    path: "/DIY_2-11/:id",
    name: "DIY_2-11",
    component: () => import("./../components/DIY_2-11"),
  },
  {
    path: "/DIY_2-12/:id",
    name: "DIY_2-12",
    component: () => import("./../components/DIY_2-12"),
  },
  {
    path: "/DIY_2-13/:id",
    name: "DIY_2-13",
    component: () => import("./../components/DIY_2-13"),
  },

  {
    path: "/DIY_3-1/:id",
    name: "DIY_3-1",
    component: () => import("./../components/DIY_3-1"),
  },
  {
    path: "/DIY_3-2/:id",
    name: "DIY_3-2",
    component: () => import("./../components/DIY_3-2"),
  },
  {
    path: "/DIY_3-3/:id",
    name: "DIY_3-3",
    component: () => import("./../components/DIY_3-3"),
  },
  {
    path: "/DIY_3-4/:id",
    name: "DIY_3-4",
    component: () => import("./../components/DIY_3-4"),
  },
  {
    path: "/DIY_3-5/:id",
    name: "DIY_3-5",
    component: () => import("./../components/DIY_3-5"),
  },
  {
    path: "/DIY_3-6/:id",
    name: "DIY_3-6",
    component: () => import("./../components/DIY_3-6"),
  },
  {
    path: "/DIY_3-7/:id",
    name: "DIY_3-7",
    component: () => import("./../components/DIY_3-7"),
  },
  {
    path: "/DIY_3-8/:id",
    name: "DIY_3-8",
    component: () => import("./../components/DIY_3-8"),
  },
  {
    path: "/DIY_3-9/:id",
    name: "DIY_3-9",
    component: () => import("./../components/DIY_3-9"),
  },
  {
    path: "/DIY_3-10/:id",
    name: "DIY_3-10",
    component: () => import("./../components/DIY_3-10"),
  },
  {
    path: "/DIY_3-11/:id",
    name: "DIY_3-11",
    component: () => import("./../components/DIY_3-11"),
  },
  {
    path: "/DIY_3-12/:id",
    name: "DIY_3-12",
    component: () => import("./../components/DIY_3-12"),
  },
  {
    path: "/DIY_3-13/:id",
    name: "DIY_3-13",
    component: () => import("./../components/DIY_3-13"),
  },
  {
    path: "/DIY_3-14/:id",
    name: "DIY_3-14",
    component: () => import("./../components/DIY_3-14"),
  },
  {
    path: "/DIY_3-15/:id",
    name: "DIY_3-15",
    component: () => import("./../components/DIY_3-15"),
  },
  {
    path: "/DIY_3-16/:id",
    name: "DIY_3-16",
    component: () => import("./../components/DIY_3-16"),
  },
  {
    path: "/DIY_4-1/:id",
    name: "DIY_4-1",
    component: () => import("./../components/DIY_4-1"),
  },
  {
    path: "/DIY_4-2/:id",
    name: "DIY_4-2",
    component: () => import("./../components/DIY_4-2"),
  },
  {
    path: "/DIY_4-3/:id",
    name: "DIY_4-3",
    component: () => import("./../components/DIY_4-3"),
  },
  {
    path: "/DIY_4-4/:id",
    name: "DIY_4-4",
    component: () => import("./../components/DIY_4-4"),
  },
  {
    path: "/DIY_4-5/:id",
    name: "DIY_4-5",
    component: () => import("./../components/DIY_4-5"),
  },
  {
    path: "/DIY_4-6/:id",
    name: "DIY_4-6",
    component: () => import("./../components/DIY_4-6"),
  },
  {
    path: "/DIY_4-7/:id",
    name: "DIY_4-7",
    component: () => import("./../components/DIY_4-7"),
  },
  {
    path: "/DIY_4-8/:id",
    name: "DIY_4-8",
    component: () => import("./../components/DIY_4-8"),
  },
  {
    path: "/DIY_1-8/:id",
    name: "DIY_1-8",
    component: () => import("./../components/DIY_1-8"),
  },
  {
    path: "/DIY_1-9/:id",
    name: "DIY_1-9",
    component: () => import("./../components/DIY_1-9"),
  },
  {
    path: "/DIY_1-10/:id",
    name: "DIY_1-10",
    component: () => import("./../components/DIY_1-10"),
  },
  {
    path: "/DIYReport/:id",
    name: "DIYReport",
    component: () => import("./../components/DIYReport"),
  },
  {
    path: "/DIYLastGeneratedReport/:id",
    name: "DIYLastGeneratedReport",
    component: () => import("./../components/DIYLastGeneratedReport"),
  },
  {
    path: "/BlindAdSummary",
    name: "BlindAdSummary",
    component: () => import("./../components/BlindAdSummary"),
  },
  {
    path: "/BlindAdSummaryPDF",
    name: "BlindAdSummaryPDF",
    component: () => import("./../components/BlindAdSummaryPDF"),
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () => import("./../components/Contact"),
  },
  {
    path: "/CreateAccount",
    name: "CreateAccount",
    component: () => import("./../components/CreateAccount"),
  },
  {
    path: "/StateMaster",
    name: "StateMaster",
    component: () => import("./../components/StateMaster"),
  },
  {
    path: "/Worksheets",
    name: "Worksheets",
    component: () => import("./../components/Worksheets"),
  },
  {
    path: "/rptOfferHistory",
    name: "rptOfferHistory",
    component: () => import("./../components/rptOfferHistory"),
  },
  {
    path: "/rptOfferDispo",
    name: "rptOfferDispo",
    component: () => import("./../components/rptOfferDispo"),
  },
  {
    path: "/rptUserAccessByDeal",
    name: "rptUserAccessByDeal",
    component: () => import("./../components/rptUserAccessByDeal"),
  },
  {
    path: "/rptUserActivityLog",
    name: "rptUserActivityLog",
    component: () => import("./../components/rptUserActivityLog"),
  },
  {
    path: "/rptLastUserActivity",
    name: "rptLastUserActivity",
    component: () => import("./../components/rptLastUserActivity"),
  },
  {
    path: "/rptOpenUploadsDeal",
    name: "rptOpenUploadsDeal",
    component: () => import("./../components/rptOpenUploadsDeal"),
  },
  {
    path: "/rptOpenUploadsBroker",
    name: "rptOpenUploadsBroker",
    component: () => import("./../components/rptOpenUploadsBroker"),
  },
  {
    path: "/DealsMain",
    name: "DealsMain",
    component: () => import("./../components/DealsMain"),
  },
  {
    path: "/DealsArchive",
    name: "DealsArchive",
    component: () => import("./../components/DealsArchive"),
  },
  {
    path: "/DealFolders/:id",
    name: "DealFolders",
    component: () => import("./../components/DealFolders"),
  },
  {
    path: "/DealOffers/:id",
    name: "DealOffers",
    component: () => import("./../components/DealOffers"),
  },
  {
    path: "/Uploads/:id",
    name: "Uploads",
    component: () => import("./../components/Uploads"),
  },
  {
    path: "/Tasks/:id",
    name: "Tasks",
    component: () => import("./../components/Tasks"),
  },
  {
    path: "/BlindAdWorksheet/:id",
    name: "BlindAdWorksheet",
    component: () => import("./../components/BlindAdWorksheet"),
  },
  {
    path: "/UploadsTemplate",
    name: "UploadsTemplate",
    component: () => import("./../components/UploadsTemplate"),
  },
  {
    path: "/DealFoldersTemplate",
    name: "DealFoldersTemplate",
    component: () => import("./../components/DealFoldersTemplate"),
  },
  {
    path: "/FolderPermissions",
    name: "FolderPermissions",
    component: () => import("./../components/FolderPermissions"),
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: () => import("./../components/ForgotPassword"),
  },

  {
    path: "/",
    name: "MainData",
    component: () => import("./../components/Main_data"),
  },

  {
    path: "/Reports",
    name: "Reports",
    component: () => import("./../components/Reports"),
  },

  {
    path: "/MyProfile",
    name: "MyProfile",
    component: () => import("./../components/MyProfile"),
  },
  {
    path: "/SystemAdmin",
    name: "SystemAdmin",
    component: () => import("./../components/SystemAdmin"),
  },

  {
    path: "/Help",
    name: "Help",
    component: () => import("./../components/Help"),
  },
  {
    path: "/User",
    name: "User",
    component: () => import("./../components/User"),
  },

  {
    path: "/Evaluation",
    component: () => import("./../components/Evaluation/EvaluationWrapper"),
    children: evaluation_routes,
  },
  {
    path: "/Foreman",
    component: () => import("./../components/Foreman/ForemanWrapper"),
    children: foreman_routes,
  },
  {
    path: "/Folders",
    name: "Folders",
    component: () => import("./../components/Folders"),
  },
  /***********new routes*****************/
  {
    path: "/Photos_pdf",
    name: "Photos_pdf",
    component: () => import("./../components/Photos_pdf"),
  },
  {
    path: "/CityMaster",
    name: "CityMaster",
    component: () => import("./../components/CityMaster"),
  },

  /***********end*****************/
  {
    path: "*",
    name: "Home",
    redirect: "/",
  },
];

<script>
export default {
  data() {
    return {
      links: [
        "Evaluation",
        "Bids",
        "EvaluationList",
        "TodayProjects",
        "Performance",
        "RepTraining",
        "Feedback",
        "Settings",
        "Drive2Shop",
        "Directory",
        "FollowUp",
        "EvaluationDetail",
        "EvaluationReschedule",
        "EvalPrep",
        "PreEvalCall",
        "EvalDrive",
        "EvalSurvey",
        "EnterBid",
        "EvalCloseout",
        "ScheduleFU",
        "AddPDF",
        "AddImages",
        "AdjustEvaluation",
        "Block",
        "CustomerInsight",
        "ScheduleFU",
        "AddImages",
        "AdjustEvaluation",
        "Foreman",
        "TodayProject",
        "TodayProjectMain",
        "TodayBidItems",
        "TodayMaterials",
        "TodayChecklist",
        "TodayFeedback",
        "TodayPhotos",
        "TodayDocuments",
        "TodayCloseOut",
        "TodayReassign",
        "AllProjects",
        "TimeClock",
        "ConfirmClock",
        "Prep",
        "PrepBidItems",
        "PrepMaterials",
        "PrepChecklist",
        "Login",
        "DriveShop",
        "ForemanTraining",
        "ForemanDirectory",
        "ForemanFeedback",
        "Drive2Job",
      ],
      mobileDevices: [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ],
    };
  },

  watch: {
    $route(to) {
      const match = this.links.indexOf(to.name);
      this.hideNav = match > -1;
      this.loginPage = to.name === "Login";
    },
  },

  methods: {
    headerToggler() {
      const match = this.links.indexOf(this.$router.currentRoute.name);
      this.hideNav = match > -1;
      if (!this.hideNav) {
        this.hideNav = this.mobileDevices.some((toMatchItem) =>
          navigator.userAgent.match(toMatchItem)
        );
      }
      return this.hideNav;
    },
    mobileDevice() {
      this.hideNav = this.mobileDevices.some((toMatchItem) =>
        navigator.userAgent.match(toMatchItem)
      );
      return this.hideNav;
    },
  },
};
</script>

import axios from "axios";
// const url = require('url');

export default new (class RequestManager {
  get(base, payload) {
    console.log('Getting..',`${base}/`, payload)
    return new Promise((resolve, reject) => {
      axios
        .get(`${base}/`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getReport(base) {
    const jasper_username = process.env.VUE_APP_JASPER_USERNAME;
    const jasper_password = process.env.VUE_APP_JASPER_PASSWORD;
    const jasper_url = process.env.VUE_APP_JASPER_URL;
    console.log("JasperURL: ", jasper_url);

    return new Promise((resolve, reject) => {
      axios
        .get(`${jasper_url}${base}`, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
          auth: {
            username: jasper_username,
            password: jasper_password,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendTwil(messageBody, recipientNumber, activityrecord) {
    console.log("Recipient Number: ", recipientNumber);
    axios({
      url: "/db/communication/send/sms/",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        messageBody: messageBody,
        recipientNumber: recipientNumber,
        recipient: activityrecord.customer_id,
      },
    })
      .then((res) => {
        const response = res.data;
        console.log("Send Twil Response: ", response);
        this.post("Sms", response);
        //mark the Activity record completed
        this.put("CustomerActivityDates", activityrecord);
        if (response.error) {
          console.log("error detected!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  sendTwilPwd(messageBody, recipientNumber, recipient) {
    console.log("Recipient Number: ", recipientNumber);
    axios({
      url: "/db/communication/send/sms/",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        messageBody: messageBody,
        recipientNumber: recipientNumber,
        recipient: recipient,
      },
    })
      .then((res) => {
        const response = res.data;
        console.log("Send Twil Response: ", response);
        this.post("Sms", response);
      })
      .catch((error) => {
        console.log("Twilio Send Error: ", error);
        alert(
          "An error has occured while attempting to send a password sms to phone number: " +
            recipientNumber +
            ".  Please contact your System Administrator for assistance."
        );
      });
  }

  getSingle(base) {
    console.log('Getting..',`${base}/`)
    return new Promise((resolve, reject) => {
      axios
        .get(`${base}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getWithParams(base, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${base}/`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getSelected(base, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${base}/`, { params: params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getFilteredWithParams(base, params, field = "project_id") {
    let get_params = "";
    if (Array.isArray(params)) {
      let p = "";
      params.forEach(function (key) {
        p += field + "=" + key + "&";
      });
      p = p.slice(0, -1);
      get_params = p;
    } else {
      get_params = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
    }

    // const get_params = new url.URLSearchParams(params);
    return new Promise((resolve, reject) => {
      axios
        .get(`${base}/?${get_params}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(base, args) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${base}/`, args)
        .then((response) => {
          resolve(response.data);
          console.log("Post Response: ", response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  put(base, args) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${base}/${args.id}/`, args)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(base, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${base}/${id}/`)
        .then((response) => {
          if (response.status === 204) {
            // handle success case
            resolve(response.data);
          } else {
            // handle error case
            let errorMessage = '';
            try {
              errorMessage = response.data.message;
            } catch (error) {
              errorMessage = 'Failed to delete record.';
            }
            console.error(errorMessage);
            alert(errorMessage);
          }         
          
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
})();

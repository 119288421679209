import RequestManager from "./RequestManager";
import { saveAs } from "file-saver";

export default new (class CommonServices {
  getDataWithParams(URL, searchText, dataset) {
    this.loading = true;
    let params = this.params ? this.params : {};
    params["search"] = searchText;
    this.listWithParams(URL, { params: params })
      .then((response) => {
        dataset = response.results;
        console.log("Dataset Items: ", dataset);
      })
      .catch((error) => {
        this.snackbar = true;
        this.snackColor = "error";
        this.snackText = "Something went wrong";
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  runFunction(functionid, value) {
    this.putData("Functions", { id: functionid, param1: value })
      .then((response) => {
        console.log("Put Function Response: ", response.id);
        this.$router.go();
      })
      .catch((error) => {
        console.log("Put Function Error: ", error);
      });
  }

  runReport(reportid, value1, value2, value3, value4, value5, value6, step) {
    if (value2 == null) {
      value2 = 0;
    }
    if (value3 == null) {
      value3 = 0;
    }
    if (value4 == null) {
      value4 = 0;
    }
    if (value5 == null) {
      value5 = 0;
    }
    if (value6 == null) {
      value6 = 0;
    }
    this.putData("ReportParams", {
      id: reportid,
      param1: value1,
      param2: value2,
      param3: value3,
      param4: value4,
      param5: value5,
      param6: value6,
    })
      .then((response) => {
        console.log("Run Report Response: ", response.id);
        //location.reload();
        if (step) {
          this.change_step(step);
        }
      })
      .catch((error) => {
        console.log("Run Report Error: ", error);
      });
  }

  sendTwilio(recipientNumber, messageBody, activityrecord) {
    return RequestManager.sendTwil(
      messageBody,
      recipientNumber,
      activityrecord
    );
  }

  sendTwilioPwd(recipientNumber, messageBody, recipient) {
    return RequestManager.sendTwilPwd(messageBody, recipientNumber, recipient);
  }

  listWithParams(args, params) {
    return RequestManager.getWithParams(args, params);
  }

  returnSelected(url, params) {
    return RequestManager.getSelected(url, params);
  }

  listFilteredWithParams(args, params) {
    return RequestManager.getFilteredWithParams(args, params);
  }

  updateData(base, data) {
    if (localStorage.getItem("canput")) return RequestManager.put(base, data);
  }

  putData(url, jsondata) {
    return RequestManager.put(url, jsondata);
  }

  postData(url, jsondata) {
    return RequestManager.post(url, jsondata);
  }

  addRecord(base, data, validate = true) {
    if (validate && !localStorage.getItem("canpost"))
      throw new Error("Can not post");
    return RequestManager.post(base, data);
  }

  getList(endpoint, payload = {}) {
    console.log("PULLING LIST FOR: " + endpoint);
    return RequestManager.get(endpoint, payload);
  }

  getItem(url) {
    console.log("PULLING LIST FOR: " + url);
    return RequestManager.getSingle(url);
  }

  calculateDistanceAPI(args, address, destination) {
    let params = {
      address: address,
      destination: destination,
    };
    return RequestManager.post(args, params);
  }

  getLatLngFromAddress(args, address) {
    let params = {
      address: address,
    };
    return RequestManager.post(args, params);
  }

  deleteRecord(url, id) {
    return RequestManager.delete(url, id);
  }

  Detail(id, url) {
    console.log("PULLING PAGE DETAIL FOR: " + url + "ID: " + id);
    return RequestManager.get(`${url}/${id}`);
  }

  ReportDetail(id, userid, url) {
    console.log(
      "PULLING REPORT DETAIL FOR: " +
        url +
        "REPORT ID: " +
        id +
        " USER ID:" +
        userid
    );
    return RequestManager.getSingle(
      `${url}/?report_id=${id}&user_id=${userid}`
    );
  }

  CustomerDetail(id, url) {
    console.log("PULLING CUSTOMER DETAIL FOR: " + url);
    return RequestManager.getSingle(`${url}/?customer=${id}`);
  }

  BidDetail(id, url) {
    console.log("PULLING BID DETAIL FOR: " + url);
    return RequestManager.get(`${url}/?bid=${id}`);
  }

  sleep(duration) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration * 1000);
    });
  }

  runJasper(report) {
    console.log("Running jasper report: " + report);
    return RequestManager.getReport(report);
  }

  downloadFile(url, filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        this.snackbar = true;
        this.snackColor = "error";
        this.error = error;
        console.log(error);
      });
    console.log("downloading", url);
  }

  async  getZipCode(cityid, stateid, targetObj, targetField) {
    try {
      const endpoint = '/ZipCodes';
      //const payload = { city_id: cityid, state_id: stateid };
      const url = `${endpoint}?city_id=${cityid}&state_id=${stateid}`.trim('/');
      console.log('URL:', url);
      const zipCodes = await this.getItem(url);
      console.log('Found ZipCodes:', zipCodes);
      if (zipCodes.length === 0) {
        throw new Error('No zip code found for the given city and state.');
      }
      console.log('First Zip', zipCodes.results[0].zipCode);

      const zipCode = zipCodes.results[0].zipCode;
      if (targetObj && targetField) {
        targetObj[targetField] = zipCode;
      }

      console.log('Broker Item Zip:',zipCode)
      return zipCodes.results[0].zipCode;
    } catch (error) {
      console.error(error);
    }
  }

  async getCityState(zipCode, targetObj, cityField, stateField) {
    try {
      const endpoint = '/ZipCodes';
      const url = `${endpoint}?zipCode=${zipCode}`.trim('/');
      console.log('URL:', url);
      const zipCodes = await this.getItem(url);
      console.log('Found ZipCodes:', zipCodes);
      if (zipCodes.length === 0) {
        throw new Error('No city and state found for the given zip code.');
      }
      console.log('City:', zipCodes.results[0].city);
      console.log('State:', zipCodes.results[0].state);
  
      const city = zipCodes.results[0].city_id;
      const state = zipCodes.results[0].state_id;
      if (targetObj && cityField) {
        targetObj[cityField] = city;
      }
      if (targetObj && stateField) {
        targetObj[stateField] = state;
      }
  
      console.log('City:', city, 'State:', state);
      return { city, state };
    } catch (error) {
      console.error(error);
    }
  }
  

})();

export const evaluation_routes = [
  {
    path: "",
    name: "Evaluation",
    component: () => import("./../components/Evaluation/EvaluationMain"),
  },
  {
    path: "EvaluationList",
    name: "EvaluationList",
    component: () => import("./../components/Evaluation/EvaluationList"),
  },
  {
    path: "AdjustEvaluation",
    name: "AdjustEvaluation",
    component: () => import("./../components/Evaluation/EvaluationAdjust"),
  },
  {
    path: "EvaluationDetail/:id",
    name: "EvaluationDetail",
    component: () => import("./../components/Evaluation/EvaluationDetail"),
  },
  {
    path: "EvaluationReschedule/:id",
    name: "EvaluationReschedule",
    component: () => import("./../components/Evaluation/EvaluationReschedule"),
  },
  {
    path: "EvalPrep/:id",
    name: "EvalPrep",
    component: () => import("./../components/Evaluation/EvalPrep"),
  },
  {
    path: "CustomerInsight/:id",
    name: "CustomerInsight",
    component: () => import("./../components/Evaluation/CustomerInsight"),
  },
  {
    path: "ReviewWarranty",
    name: "ReviewWarranty",
    component: () => import("./../components/Evaluation/ReviewWarranty"),
  },
  {
    path: "PreEvalCall/:id",
    name: "PreEvalCall",
    component: () => import("./../components/Evaluation/PreEvalCall"),
  },
  {
    path: "EvalDrive/:id",
    name: "EvalDrive",
    component: () => import("./../components/Evaluation/EvalDrive"),
  },
  {
    path: "EnterBid/:id",
    name: "EnterBid",
    component: () => import("./../components/Evaluation/EnterBid"),
  },
  {
    path: "AddPDF",
    name: "AddPDF",
    component: () => import("./../components/Evaluation/AddPDF"),
  },
  {
    path: "AddImages",
    name: "AddImages",
    component: () => import("./../components/Evaluation/AddImages"),
  },
  {
    path: "EditBidPack",
    name: "EditBidPack",
    component: () => import("./../components/Evaluation/EditBidPack"),
  },
  {
    path: "EvalCloseout/:id",
    name: "EvalCloseout",
    component: () => import("./../components/Evaluation/EvalCloseout"),
  },
  {
    path: "ScheduleFU",
    name: "ScheduleFU",
    component: () => import("./../components/Evaluation/ScheduleFU"),
  },
  {
    path: "ScheduleFU2",
    name: "ScheduleFU2",
    component: () => import("./../components/Evaluation/ScheduleFU2"),
  },
  {
    path: "Bids",
    name: "Bids",
    component: () => import("./../components/Evaluation/Bids"),
  },
  {
    path: "TodayProjects",
    name: "TodayProjects",
    component: () => import("./../components/Evaluation/TodayProjects"),
  },
  {
    path: "Performance",
    name: "Performance",
    component: () => import("./../components/Evaluation/Performance"),
  },
  {
    path: "Feedback",
    name: "Feedback",
    component: () => import("./../components/Evaluation/Feedback"),
  },
  {
    path: "Settings",
    name: "Settings",
    component: () => import("./../components/Evaluation/Settings"),
  },
  {
    path: "Block",
    name: "Block",
    component: () => import("./../components/Evaluation/Block"),
  },
  {
    path: "Directory",
    name: "Directory",
    component: () => import("./../components/Evaluation/Directory"),
  },
  {
    path: "Drive2Shop",
    name: "Drive2Shop",
    component: () => import("./../components/Evaluation/Drive2Shop"),
  },
  {
    path: "FollowUp",
    name: "FollowUp",
    component: () => import("./../components/Evaluation/FollowUp"),
  },
  {
    path: "RepTraining",
    name: "RepTraining",
    component: () => import("./../components/Evaluation/RepTraining"),
  },
  {
    path: "EvalSurvey",
    name: "EvalSurvey",
    component: () => import("./../components/Evaluation/EvalSurvey"),
  },
];
